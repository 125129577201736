import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DebounceInput } from 'react-debounce-input'
import Select from 'react-select'
import { toast } from 'react-toastify'

import { GET, POST, PUT } from 'helpers/api'

function ViewPurchase() {
	const { id } = useParams();
	const location = useLocation();

	const { t } = useTranslation();
	const navigate = useNavigate();

	const reduxSettings = useSelector(state => state.settings)

	const [posId, setPosId] = useState([])
	const [Uoms, setUoms] = useState([])
	const [poses, setPoses] = useState([])
	const [dealerId, setDealerId] = useState(null)
	const [dealers, setDealers] = useState([])
	const [search, setSearch] = useState('')
	const [note, setNote] = useState('')
	const [searchDataProduct, setSearchDataProduct] = useState([])
	const [data, setData] = useState({ id: 0 })
	const [dataProduct, setDataProduct] = useState([])

	async function create() {
		var sendData = {
			products: [],
			posId: posId,
			dealerId: dealerId,
			note: note,
			totalAmount: 0, // Initialize as 0
			totalQuantity: 0, // Initialize as 0
		};

		if (data['id']) sendData['id'] = data['id'];

		// Get form data
		const formData = getFormData();

		// Calculate totals
		const filteredProducts = formData.filter(product => product.quantity && parseFloat(product.quantity) > 0);
		const totals = filteredProducts.reduce(
			(totals, product) => {
				const quantity = parseFloat(product.quantity) || 0; // Convert quantity to number or default to 0
				const price = parseFloat(product.price) || 0;       // Convert price to number or default to 0
				totals.totalQuantity += quantity;
				totals.totalAmount += quantity * price;
				return totals;
			},
			{ totalQuantity: 0, totalAmount: 0 } // Initial totals
		);

		// Assign filtered products and totals to sendData
		sendData.products = filteredProducts;
		sendData.totalQuantity = totals.totalQuantity;
		sendData.totalAmount = totals.totalAmount;

		console.log("sendData:", sendData);

		if (totals.totalAmount > 0) {
			const response = await POST('/services/web/api/sd-purchase', sendData, { loader: true })
			if (response) {
				navigate('/sd-purchase')
			}
		}


	}

	const getFormData = () => {
		return dataProduct?.map((item, index) => {
			const quantityInput = document.querySelector(`input[name="quantity"][productQuantity="${item.id}"]`);
			const priceInput = document.querySelector(`input[name="price"][productPrice="${item.id}"]`);

			const quantity = quantityInput ? parseFloat(quantityInput.value) || 0 : 0; // Convert to number or default to 0
			const price = priceInput ? parseFloat(priceInput.value) || 0 : 0;         // Convert to number or default to 0

			return {
				name: item.name,
				id: item.id,
				barcode: item.barcode,
				quantity: quantity.toString(), // Convert back to string if needed
				price: price.toString(),       // Convert back to string if needed
				totalAmount: quantity * price, // Calculate totalAmount
			};
		});
	};


	useEffect(() => {
		console.log(data);

	}, [data])

	async function getPoses() {
		try {
			const temporaryPoses = await GET('/services/web/api/pos-helper');
			if (temporaryPoses) {
				setPoses(temporaryPoses);
				if (temporaryPoses.length > 0) {
					setPosId(Number(location.state?.details.posId))
				}
			}
		} catch (error) {
			console.error('Error fetching poses:', error);
		}
	}

	async function getDealers() {
		try {
			const temporaryDealers = await GET('/services/web/api/dealer-helper');
			if (temporaryDealers) {
				setDealers(temporaryDealers);
				if (temporaryDealers.length > 0) {
					setDealerId(Number(location.state?.details.dealerId))
				}
			}
		} catch (error) {
			console.error('Error fetching dealers:', error);
		}
	}

	async function getUoms() {
		try {
			const response = await GET('/services/web/api/product-uom-helper')
			if (response) {
				setUoms(response)
			}
		} catch (error) {
			console.error('Error fetching dealers:', error);
		}
	}

	function isNumeric(str) {
		if (typeof str !== "string") return false; // We only process strings
		return !isNaN(str) && !isNaN(parseFloat(str));
	}


	useEffect(() => {
		getPoses();
		getDealers();
		getUoms()
		var accept_product = location.state?.details

		let Products = {

			name: accept_product.details.product_name,
			barCode: accept_product.details.bar_code,
			quantity: accept_product.details.quantity,
			price: accept_product.details.price,
			uomName: accept_product.details.uomName,
			product_id: accept_product.details.product_id
		}


		setDataProduct(accept_product.details);
		// if (id) getData();
	}, [id]); // Re-run effect if `id` changes


	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('ORDERS_SD')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{id ? t('TRACKING') : t('TRACKING')}</h6>
					</div>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body position-relative">
					<div className="row">
						<div className="col-md-6">
							<div className="form-group">
								<label>{t('POS')}<span className="required-mark">*</span></label>
								<Select
									options={poses}
									menuIsOpen={false}
									value={poses.find(option => option.id === posId)} // Access posId from `data`
									onChange={(option) => setPosId(option.id)} // Ensure proper key is updated
									placeholder=""
									noOptionsMessage={() => t('LIST_IS_EMPTY')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<label>{t('DEALER')}<span className="required-mark">*</span></label>
								<Select
									options={dealers}
									menuIsOpen={false}
									value={dealers.find(option => option.id === dealerId)} // Access posId from `data`
									// onChange={(option) => changeDealerId(dealers, option.id)} // Ensure proper key is updated
									placeholder=""
									noOptionsMessage={() => t('LIST_IS_EMPTY')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>
						</div>

						<div className="form-group position-relative mb-0">
							<label>{t('COMMENT')}</label>
							<input type="text" className="form-control" value={data.note} onChange={(e) => setNote(e.target.value)} disabled={true} />
						</div>

					</div>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body position-relative">
					<div className="row">
						<div className="form-group position-relative mb-0">
							{(data.id == null && dealerId) &&
								<DebounceInput
									value={search}
									type="text"
									className="form-control"
									placeholder={t('SEARCH') + '...'}
									debounceTimeout={1000}
								/>
							}

							<br />
						</div>
						<div className="table-responsive mb-3">
							<table className="table table-striped mb-0">
								<thead>
									<tr>
										<th className="col-md-4">{t('NAME_OF_PRODUCT')}</th>
										<th className="text-center">{t('BARCODE')}</th>
										<th className="text-center">{t('QUANTITY')}</th>
										<th className="text-center">{t('UNIT')}</th>
										<th className="text-center">{t('PRICE')}</th>
										{data.id == null &&
											<th className="text-center table-control">
												{t('ACTION')}
											</th>
										}
									</tr>
								</thead>
								<tbody>
									{dataProduct?.map((item, index) => (
										<tr key={index} className={`vertical-middle`}>
											<td className={`${isNumeric(item.product_id) ? '' : 'text-danger'}`}>{item.product_name}</td>
											<td className={`text-center  ${isNumeric(item.product_id) ? '' : 'text-danger'}`}>{item.bar_code}</td>
											<td className={`text-center  ${isNumeric(item.product_id) ? '' : 'text-danger'}`}>
												<input
													type="text"
													className="auto-width-input"
													name="quantity"
													value={item.quantity}
													id={index + 1}
													productQuantity={item.id}
													disabled
												/>
											</td>
											<td className={`text-center  ${isNumeric(item.product_id) ? '' : 'text-danger'}`}>
												{Uoms.length > 0 &&
													Uoms.map(option => {
														if (option.id == item.uom_id) {
															return <span key={option.id}>{option.name} </span>;
														}
														return null;
													})
												}
											</td>
											<td className={`text-center  ${isNumeric(item.product_id) ? '' : 'text-danger'}`}>
												<input
													type="text"
													className={`auto-width-input ${item.price?.length > 9 ? "required-border second" : ""}`}
													name="price"
													value={item.price}
													productPrice={item.id}
													disabled
												/>
											</td>
										</tr>
									))}

								</tbody>
							</table>
						</div>

						{data.id == null &&
							<div className="d-flex flex-wrap items-center justify-content-end">
								<button type="button" className="btn btn-primary btn-rounded btn-wide"
									disabled={data?.length === 0 || dataProduct?.length === 0}
									onClick={create}>
									{t("SAVE")}
								</button>
							</div>
						}
					</div>
				</div>
			</div>
		</>
	)
}

export default ViewPurchase
