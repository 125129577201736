import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { SET_LOCK_SCREEN } from 'store/settings/actions'
import { useTranslation } from 'react-i18next'
//import io from 'socket.io-client'
import DatePicker from "react-datepicker";

// Reactstrap
import { Modal } from 'react-bootstrap'
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle
} from "reactstrap"

// Import menuDropdown
import LanguageDropdown from "../topbarDropdown/LanguageDropdown";

import logoIcon from "../../assets/images/logo.png";
import clickImage from '../../assets/images/payments/click-dark.png';

import { POST, GET } from 'helpers/api'
import { clearTemporaryStorage, formatMoney } from 'helpers/helpers'
import axios from "axios";

function Header() {
	const { t } = useTranslation()
	const navigate = useNavigate();
	const dispatch = useDispatch()

	//const socketRef = useRef()

	const layoutSelector = useSelector(state => state.Layout)

	const role = localStorage.getItem('role')

	const [modalLogout, setModalLogout] = useState(false)
	const [balance, setBalance] = useState(0)
	const [notifications, setNotifications] = useState([]);
	const [showDropdown, setShowDropdown] = useState(false);
	const [selectedNotification, setSelectedNotification] = useState(null);

	const [billingMenu, setBillingMenu] = useState(false)
	const [posData, setPosData] = useState([]);
	const [showModalClick, setShowModalClick] = useState(false);
	const [selectedPosId, setSelectedPosId] = useState(null);
	const [paidFromDateClick, setPaidFromDateClick] = useState(null);
	const [amountIn, setAmountIn] = useState("");

	const [rightDropdownOpen, setRightDropdownOpen] = useState(false);
	const [ShowPorfile, setShowPorfile] = useState(false);
	const [showPaymentModalClick, setshowPaymentModalClick] = useState(false);
	const [PaymentMethod, setPaymentMethod] = useState(0);

	const toggleRightDropdown = () => {
		setRightDropdownOpen(!rightDropdownOpen);
	};

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPosData(response)
		}
	}

	// Handle number input change
	const handleNumberChange = (event) => {
		const value = event.target.value;
		if (/^\d*$/.test(value)) { // Ensure input is an integer
			setAmountIn(value);
		}
	};

	useEffect(() => {
		getPoses()
	}, [])


	const toggleBilling = () => {
		setBillingMenu(!billingMenu)
	}

	/* CLICK */
	// Handle select change
	const handleSelectChangeClick = (event) => setSelectedPosId(event.target.value);

	const handleDateChangeClick = (event) => setPaidFromDateClick(event);

	// Open modal on button click
	const handleOpenModalClick = () => setShowModalClick(true);

	// Close modal
	const handleCloseModalClick = () => {
		setShowModalClick(false);
		setSelectedPosId(null);
		setAmountIn("");
	};


	const redirectToClick = async () => {
		try {
			const sendData = {
				id: selectedPosId,
				amountIn: amountIn,
				paidFrom: paidFromDateClick
			};
			// Replace with your actual API endpoint
			const response = await POST('/services/web/api/get-click-url', sendData, { loader: true })

			if (response && response.data && response.data.url) {
				const redirectUrl = response.data.url;

				// Redirect to the URL received from the API
				window.location.href = redirectUrl;
			} else {
				console.error('Failed to fetch URL');
			}
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const isButtonDisabledClick = amountIn < 1000 || !selectedPosId || !paidFromDateClick;
	/* END CLICK */

	function toggleFullscreen() {
		if (
			!document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
			!document.webkitFullscreenElement
		) {
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(
					Element.ALLOW_KEYBOARD_INPUT
				);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}

	function lockScreen() {
		dispatch(SET_LOCK_SCREEN())
	}

	function tToggle() {
		var body = document.body;
		let btn = document.querySelector('.navbar-header')
		btn.classList.toggle('left-margin')
		body.classList.toggle("vertical-collpsed");
		body.classList.toggle("sidebar-enable");
	}


	async function logout() {
		if (process.env.NODE_ENV === 'development') {
			dispatch({ type: 'USER_LOGGED_OUT', payload: null })
			clearTemporaryStorage()
			navigate('/auth/login')
			window.location.reload()
			return
		}
		const response = await POST('/auth/logout')
		if (response) {
			clearTemporaryStorage()
			navigate('/auth/login')
			window.location.reload()
		}
	}

	async function getBalance() {
		const response = await GET('/services/web/api/pos-balance')
		setBalance(response)
	}

	async function getNotifications() {
		const response = await GET('/services/web/api/unread-notifications')
		setNotifications(response)
	}

	const handleDropdownToggle = () => {
		setShowDropdown(!showDropdown);
	};

	const handleNotificationClick = (notification) => {
		setSelectedNotification(notification);
	};

	const handleCloseModal = () => {
		setSelectedNotification(null);
	};



	let locale = "ru"
	if (localStorage.getItem("I18N_LANGUAGE") === 'ru') locale = "ru"
	else if (localStorage.getItem("I18N_LANGUAGE") === 'uz-Latn-UZ') { locale = "lat" }
	else if (localStorage.getItem("I18N_LANGUAGE") === 'uz-Cyrl-UZ') { locale = "cyr" }


	useEffect(() => {
		//socketConnection()
		if (role !== 'ROLE_ORGANIZATION') {
			getBalance()
			getNotifications()
		}

		// return () => {
		// 	socketRef?.current?.disconnect()
		// }
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const [shortTexts, setShortTexts] = useState([]);

	useEffect(() => {
		// Function to process and extract plain text for each language
		const processHtmlContentByLanguage = (htmlContent) => {
			try {
				// Parse the JSON message
				const parsedContent = JSON.parse(htmlContent);

				// Create a temporary div element for each language
				const processText = (html) => {
					const tempDiv = document.createElement('div');
					tempDiv.innerHTML = html;
					return tempDiv.textContent || tempDiv.innerText || '';
				};

				// Extract plain text for each language
				return {
					ru: processText(parsedContent.ru || ''),
					lat: processText(parsedContent.lat || ''),
					cyr: processText(parsedContent.cyr || '')
				};
			} catch (error) {
				console.error('Error parsing or processing content:', error);
				return { ru: 'Invalid content', lat: 'Invalid content', cyr: 'Invalid content' };
			}
		};

		// Process each notification
		const processedTexts = notifications.map((item) => ({
			id: item.id,
			createdAt: item.createdAt,
			...processHtmlContentByLanguage(item.message)
		}));

		// Log the processed messages
		console.log(processedTexts);

		// Update the state or handle the processed texts if needed
		setShortTexts(processedTexts);
		// setProcessedTexts(processedTexts);
	}, [notifications]);

	const PAYMENT_METHODS = [
		{
			id: 1,
			name: "Click",
			image: clickImage,
		}
	]


	const OpenPaymentModal = (method_idx) => {
		setPaymentMethod(method_idx)
		setshowPaymentModalClick(true)
	}

	const ClosePaymentModal = () => {
		setshowPaymentModalClick(false)
	}
	const dropdownRef = useRef(null);
	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setShowPorfile(false); // Faqat tashqariga bosilganda yopish
			}
		};

		document.addEventListener("mousedown", handleOutsideClick);
		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, [setShowPorfile]);


	const handleVoiceCommand = () => {
		const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
		recognition.lang = 'uz-UZ'; // O'zbek tili
		recognition.start();

		recognition.onresult = (event) => {
			const transcript = event?.results?.[0]?.[0]?.transcript;
			console.log(transcript);

			if (transcript?.includes("mahsulot") || transcript?.includes("qabul qilish")) {
				// Foydalanuvchini "Mahsulotlarni qabul qilish" bo'limiga yo'naltirish
				// window.location.href = "/documents-in";
				navigate('/documents-in')
				// Ovozli yordamchi tushuntirish beradi
				// const speech = new SpeechSynthesisUtterance("Mahsulotlarni qabul qilish bo'limiga o'tdingiz. Bu yerda yangi mahsulotlarni qo'shishingiz va ularni ro'yxatdan o'tkazishingiz mumkin.");
				// window.speechSynthesis.speak(speech);
			}
		};
	}



	// Foydalanuvchi savolini yuboradi
	// 	useEffect(() => {
	// 		// handleChatbotQuestion();
	// 	}, [])

	// 	const handleChatbotQuestion = async () => {
	// 		const userQuery = "Mahsulotlarni qabul qilishni boshlash uchun nima qilish kerak?";
	// 		const chatbotResponse = await handleChatbotQuery(userQuery);
	// 		console.log(chatbotResponse); // Chatbot javobini ko'rsatadi
	// 	};

	// 	const handleChatbotQuery = async (query) => {
	// 		const response = await fetch('https://api.openai.com/v1/chat/completions', {
	// 				method: 'POST',
	// 				headers: {
	// 						'Content-Type': 'application/json',
	// 						'Authorization': 'Bearer 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',
	// 				},
	// 				body: JSON.stringify({
	// 						model: "gpt-3.5-turbo",
	// 						messages: [{ role: "user", content: 'Mahsulotlarni qabul qilishni boshlash uchun nima qilish kerak?' }],
	// 				}),
	// 		});
	// 		const data = await response.json();
	// 		return data?.choices?.[0]?.message?.content;
	// };

	const [prayerTimes, setPrayerTimes] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchPrayerTimes = async () => {
			try {
				const response = await axios.get(
					`https://islomapi.uz/api/present/day?region=Toshkent`
				);
				setPrayerTimes(response?.data.times);
			} catch (error) {
				console.error("Error fetching prayer times:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchPrayerTimes();
	}, []);

	const getFormattedDate = () => {
		const today = new Date();
		const day = String(today.getDate()).padStart(2, "0"); // Добавляет 0 перед цифрами < 10
		const month = String(today.getMonth() + 1).padStart(2, "0"); // Месяцы начинаются с 0
		const year = today.getFullYear();
		return `${day}.${month}.${year}`;
	};

	return (
		<>
			<header id="page-topbar" className={`d-flex justify-content-between align-items-center ${layoutSelector.showSidebar ? 'sidebar-left' : 'd-none sidebar-active-left'} flex-wrap`}>
				<div className="navbar-header  p-0">
					<div className="d-flex align-items-center">
						<button
							type="button"
							onClick={() => tToggle()}
							className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn me-2"
							id="vertical-menu-btn"
						>
							<i className="uil-bars fz-20" />
						</button>
					</div>
				</div>
				{/* <button className="mt-2" onClick={handleChatbotQuery}>OVOZ</button> */}
				<div className="d-flex">
					<div className="d-flex align-items-center flex-wrap ">
						<LanguageDropdown />

						<button
							type="button"
							onClick={() => handleDropdownToggle()}
							className="btn header-item noti-icon waves-effect me-2 position-relative"
							style={{
								padding: '10px',
								color: 'white',
								border: 'none',
								borderRadius: '8px',
								cursor: 'pointer',
								outline: 'none',
							}}
						>
							<i className="uil-bell position-relative" style={{ fontSize: '24px' }}>
								{notifications.length > 0 && (
									<span
										className="position-absolute bg-warning text-white rounded-circle d-flex align-items-center justify-content-center"
										style={{
											top: '-5px',
											right: '-10px',
											padding: '2px 6px',
											fontSize: '10px',
											fontWeight: 'bold',
											border: '2px solid white',
										}}
									>
										{notifications.length}
									</span>
								)}
							</i>
							{showDropdown && (
								<div
									className="position-absolute"
									style={{
										top: '50px',
										right: '0',
										minWidth: '250px',
										backgroundColor: '#ffffff',
										border: '1px solid #ddd',
										borderRadius: '8px',
										boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
										zIndex: 1050,
										overflow: 'hidden',
										color: 'black',
									}}
								>
									{notifications.length > 0 ? (
										notifications.map((notification) => {
											// Safely parse the message content
											let parsedMessage = {};
											try {
												parsedMessage = JSON.parse(notification.message);
											} catch (error) {
												console.error("Error parsing message:", error);
											}

											// Get the message for the selected language
											const localizedText = parsedMessage[locale] || 'No content for this language';

											// Convert the HTML content to plain text
											const tempDiv = document.createElement('div');
											tempDiv.innerHTML = localizedText;
											const plainText = tempDiv.textContent || tempDiv.innerText || 'No content available';

											return (
												<div
													key={notification.id}
													onClick={() => handleNotificationClick(notification)}
													style={{
														padding: '12px 16px',
														borderBottom: '1px solid #f0f0f0',
														cursor: 'pointer',
														transition: 'background-color 0.2s ease-in-out',
													}}
													className="hover:bg-gray-100"
												>
													{plainText}
												</div>
											);
										})
									) : (
										<div
											style={{
												padding: '12px 16px',
												color: '#666',
												fontStyle: 'italic',
												textAlign: 'center',
											}}
										>
											No notifications
										</div>
									)}
								</div>
							)}

						</button>



						<button
							type="button "
							onClick={() => toggleFullscreen()}
							className="btn header-item noti-icon waves-effect me-2 d-none d-md-flex"
						>
							<i className="uil-minus-path"></i>
						</button>

						<button
							type="button"
							onClick={() => lockScreen()}
							className="btn header-item noti-icon waves-effect me-2 "
						>
							<i className="uil-lock"></i>
							<div className="d-none d-md-none">
								<div className=" align-items-center me-2">
									<button type="button" className="btn header-item  noti-icon waves-effect" onClick={() => setModalLogout(true)}>
										<div className="d-flex justify-content-between align-items-center">
											<div className="d-block text-start">
												<div>{t('LOGIN')}: {JSON.parse(localStorage.getItem('authUser'))?.username?.replaceAll('!2929s', '')}</div>
												<div>{t('BALANCE')}: {formatMoney(balance, 0)}</div>
											</div>
											<i className="uil-signin"></i>
										</div>
									</button>
								</div>


								<Dropdown isOpen={billingMenu} toggle={toggleBilling} className="d-inline-block me-2">
									<DropdownToggle className="btn btn-success text-white header-item waves-effect d-inline-flex align-items-center gap-2">
										<i className="uil-dollar-sign" style={{ fontSize: '24px' }}></i>
										<p className="mb-0 ">{t('MAKE_PAYMENT_FOR_BILLLING')}</p>
									</DropdownToggle>
									<DropdownMenu className="dropdown-menu-end">
										<DropdownItem onClick={handleOpenModalClick} className="notify-item">
											<img src={clickImage} alt="react" className="me-1" height="20" />
											<span className="align-middle">Click</span>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							</div>
						</button>




						<div style={{ color: ShowPorfile ? '#25B07A' : '' }} onClick={() => setShowPorfile(true)} type="button" className="me-4 cursor btn position-relative">
							<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24"><g fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"><path d="M16 9a4 4 0 1 1-8 0a4 4 0 0 1 8 0m-2 0a2 2 0 1 1-4 0a2 2 0 0 1 4 0" /><path d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11s11-4.925 11-11S18.075 1 12 1M3 12c0 2.09.713 4.014 1.908 5.542A8.99 8.99 0 0 1 12.065 14a8.98 8.98 0 0 1 7.092 3.458A9 9 0 1 0 3 12m9 9a8.96 8.96 0 0 1-5.672-2.012A6.99 6.99 0 0 1 12.065 16a6.99 6.99 0 0 1 5.689 2.92A8.96 8.96 0 0 1 12 21" /></g></svg>
						</div>
						{ShowPorfile && (
							<div
								ref={dropdownRef}
								className="position-absolute"
								style={{
									position: "fixed",
									top: '60px',
									right: '20px',
									minWidth: '250px',
									backgroundColor: '#ffffff',
									border: '1px solid #ddd',
									borderRadius: '8px',
									boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
									zIndex: 1050,
									color: 'black',
								}}
								onClick={(e) => e.stopPropagation()}
							>
								<div
									// onClick={() => handleNotificationClick(notification)}
									style={{
										padding: '12px 0',
										borderBottom: '1px solid #f0f0f0',
										cursor: 'pointer',
										transition: 'background-color 0.2s ease-in-out',
									}}
									className=""
								>
									<div style={{ padding: '5px 16px' }} className="d-flex align-items-center justify-content-between hover-bg-gray">
										<p className="" style={{ color: "gray" }}>{t('LOGIN')}: </p>
										<div className="fs-5">{JSON.parse(localStorage.getItem('authUser'))?.username?.replaceAll('!2929s', '')}</div>
									</div>
									<div style={{ padding: '5px 16px' }} className="d-flex align-items-center justify-content-between hover-bg-gray">
										<p className="" style={{ color: "gray" }}>{t('BALANCE')}: </p>
										<div className="fs-5">{balance < 0 ? <span style={{ color: "red" }}>{formatMoney(balance, 0)}</span> : formatMoney(balance, 0)}</div>
									</div>
									<div style={{ padding: '2px 16px' }} onClick={() => setModalLogout(true)} className="d-flex align-items-center justify-content-between hover-bg-gray">
										<p className="" style={{ color: "gray" }}>{t('LOGOUT')}: </p>
										<div className="fs-5" style={{ color: "#f46a6a" }}><i className="uil-signin"></i></div>
									</div>
									<Dropdown style={{ padding: '2px 16px' }} isOpen={billingMenu} toggle={handleOpenModalClick} className="w-100 mt-3">
										<DropdownToggle style={{ width: "100%" }} className="d-flex btn btn-success justify-content-center align-items-center">
											<i className="uil-dollar-sign" style={{ fontSize: '24px' }}></i>
											<p className="mb-0 ">{t('MAKE_PAYMENT_FOR_BILLLING')}</p>
										</DropdownToggle>
										<DropdownMenu className="">
											<DropdownItem onClick={handleOpenModalClick} className="notify-item">
												<img src={clickImage} alt="click" className="me-1" height="20" />
												<span className="align-middle">Click</span>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								</div>
							</div>
						)}
					</div>




					{/* Mobile View */}
					{/* <div style={{ backgroundColor: "white" }} className="d-flex align-items-center me-2 d-flex d-md-none">
						<div
							onClick={toggleRightDropdown}
							className="fs-4"
						>
							<i className="uil-ellipsis-v"></i>
						</div>

						{rightDropdownOpen && (
							<div className="position-absolute bg-white border" style={{ top: '50px', right: '10px', zIndex: 1050 }}>
								<div className="d-flex align-items-center justify-content-between w-100 me-2">
									<div type="button" className="btn header-item  noti-icon waves-effect" onClick={() => setModalLogout(true)}>
										<div className="d-flex w-100 justify-content-between align-items-center">
											<div className="d-block text-start">
												<div>{t('LOGIN')}: {JSON.parse(localStorage.getItem('authUser'))?.username?.replaceAll('!2929s', '')}</div>
												<div>{t('BALANCE')}: {formatMoney(balance, 0)}</div>
											</div>
											<i className="uil-signin"></i>
										</div>
									</div>
								</div>

								<Dropdown isOpen={billingMenu} toggle={toggleBilling} className="d-inline-block me-2" >
									<DropdownToggle className="btn btn-success text-white header-item waves-effect d-inline-flex align-items-center gap-2">
										<i className="uil-dollar-sign" style={{ fontSize: '24px' }}></i>
										<p className="mb-0 ">{t('MAKE_PAYMENT_FOR_BILLLING')}</p>
									</DropdownToggle>
									<DropdownMenu className="" >
										<DropdownItem onClick={handleOpenModalClick} className="notify-item">
											<img src={clickImage} alt="react" className="me-1" height="20" />
											<span className="align-middle">Click</span>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>

							</div>
						)}
					</div> */}
				</div>
			</header>


			{/* choose Payment Method */}
			<Modal show={showModalClick} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={handleCloseModalClick}>
				<Modal.Header closeButton>
					<Modal.Title>
						To'lov usulini tanlang
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{PAYMENT_METHODS.map((item, index) => {
						return (
							<div onClick={(_) => OpenPaymentModal(index)} style={{ border: "1px solid #34C38F", width: "120px", borderRadius: "10px", }} className=" p-2 d-grid justify-content-center align-items-center text-center gap-1 cursor payment_card">
								<img style={{ width: "60px", height: "60px", borderRadius: "50%" }} className="" src={item.image} alt={item.name} />
								<h5 >{item.name}</h5>
							</div>
						)
					})}
				</Modal.Body>
			</Modal>
			{/* end choose Payment Method */}
			{/* MODAL Click.uz */}
			<Modal show={showPaymentModalClick} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={ClosePaymentModal}>
				<Modal.Header closeButton>
					<Modal.Title>
						{PAYMENT_METHODS[PaymentMethod].name}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form autoComplete="off">
						<div className="form-group">
							<label>{t('AMOUNT')}<span className="required-mark">*</span></label>
							<input type="text" value={amountIn} className="form-control" onChange={handleNumberChange} />
						</div>
						<div className="form-group">
							<label>{t('POS')}<span className="required-mark">*</span></label>
							<select value={selectedPosId || ""} onChange={handleSelectChangeClick}>
								<option value="" disabled>{t('CHOOSE_POS')}</option>
								{posData.map((item) => (
									<option key={item.id} value={item.id}>
										{item.name}
									</option>
								))}
							</select>
						</div>
						<div className="form-group">
							<label>{t('PAID_FROM')}<span className="required-mark">*</span></label>
							<DatePicker className="form-control"
								dateFormat="dd.MM.yyyy"
								required="required"
								selected={paidFromDateClick}
								onChange={(date) => {
									setPaidFromDateClick(date)
								}} />
						</div>
						<div className="alert alert-warning mt-2">
							{t('BILLING_NOTIFICATION')}
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={ClosePaymentModal}>{t('CANCEL')}</button>
							<button type="button" className="btn btn-primary w-100" onClick={redirectToClick} disabled={isButtonDisabledClick}>{t('GO_TO_PAYMENT')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* end MODAL click.uz */}

			<Modal show={modalLogout} animation={false}
				centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setModalLogout(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('LOGOUT')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t('ARE_YOU_SURE_YOU_WANT_LOGOUT')}
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100">
						<button className="btn btn-outline-danger w-100 me-2" onClick={() => setModalLogout(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => logout()}>{t('CONFIRM')}</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Header

