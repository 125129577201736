import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux"
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import XLSX from 'xlsx';

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import Rightbar from './Rightbar'
import { PGET, GET } from 'helpers/api'
import { formatDateWithTime, returnSign, formatDateBackend, findFromArrayById, formatMoney, formatDate } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]
	const statuses = [{ "id": 0, "name": t('SUCCESS') }, { "id": 1, "name": t('PARTIAL_RETURN') }, { "id": 2, "name": t('RETURN') }, { "id": 3, "name": t('DISCOUNT') }]
	const paymentTypes = [{ 'id': 1, 'name': t('CASH') }, { 'id': 2, 'name': t('TERMINAL') }]

	const [poses, setPoses] = useState([])
	const [cashiers, setCashiers] = useState([])

	const [showRightBar, setShowRightBar] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [totalCount, setTotalCount] = useState(0)
	const [totalAmount, setTotalAmont] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: reduxAccount.posId,
		cashierId: null,
		currencyId: null,
		returned: null,
		paymentTypeId: null,
		startDate: null,
		endDate: null,
		search: ''
	})
	const [pagination, setPagination] = useState({
		url: '/services/web/api/cheque-pageList',
		response: null
	})
	const [data, setData] = useState([])

	async function toggleRightbar(boolean = false, item, index) {
		var dataCopy = [...data]

		for (let i = 0; i < dataCopy.length; i++) {
			dataCopy[i]['showSidebar'] = false
		}
		if (boolean) {
			const response = await GET(`/services/web/api/cheque-itemsList/` + item.id, {}, { loader: true })
			dataCopy[index]['itemsList'] = response
			dataCopy[index]['showSidebar'] = boolean

			setSelectedItem(dataCopy[index])
			setShowRightBar(boolean)
		} else {
			setShowRightBar(boolean)
			setSelectedItem({})
		}
	}

	async function searchWithFilter(exportToExcel = false) {
		var filterUrl = `/services/web/api/cheque-pageList/${filterData.posId}`
		var urlParams = ""
		checkFilter()

		if (filterData.cashierId)
			urlParams += returnSign(urlParams) + 'cashierId=' + filterData.cashierId
		if (filterData.currencyId)
			urlParams += returnSign(urlParams) + 'currencyId=' + filterData.currencyId
		if (filterData.returned)
			urlParams += returnSign(urlParams) + 'returned=' + filterData.returned
		if (filterData.paymentTypeId)
			urlParams += returnSign(urlParams) + 'paymentTypeId=' + filterData.paymentTypeId
		if (filterData.startDate)
			urlParams += returnSign(urlParams) + 'startDate=' + filterData.startDate
		if (filterData.endDate)
			urlParams += returnSign(urlParams) + 'endDate=' + filterData.endDate
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if (`/services/web/api/cheque-pageList/${filterData.posId}` + urlParams !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		if (exportToExcel) {
			filterUrl = `/services/web/api/cheque-excel/${filterData.posId}` + urlParams + returnSign(filterUrl)

			var excelHeaders = [[t('POS'), t('CASHBOX'), t('SHIFT_NUMBER'), t('CASHIER'), t('CHECK_NUMBER'), t('CHEQUE_DATE'),
			t('SYNC_DATE'), t('STATUS'), t('TOTAL')]]
			const response = await GET(filterUrl)
			for (let i = 0; i < response.length; i++) {
				var excelItems = []
				excelItems.push(response[i].posName)
				excelItems.push(response[i].cashboxName)
				excelItems.push(response[i].shiftNumber)
				excelItems.push(response[i].cashierName)
				excelItems.push(response[i].chequeNumber)
				excelItems.push(formatDateWithTime(response[i].chequeDate))
				excelItems.push(formatDateWithTime(response[i].createdDate))
				if (response[i].returned === 0) excelItems.push(t('SUCCESS'))
				if (response[i].returned === 1) excelItems.push(t('PARTIAL_RETURN'))
				if (response[i].returned === 2) excelItems.push(t('RETURN'))
				excelItems.push(response[i].totalPrice)
				excelHeaders.push(excelItems)
			}
			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('CHEQUES') + ".xlsx");
		} else {
			const response = await PGET(filterUrl, {}, { loader: true })
			if (response) {
				setTotalCount(response.headers['x-total-count'])
				setData(response.data)
				setPagination({ ...pagination, 'url': filterUrl, 'response': response })
			}
			filterUrl = `/services/web/api/cheque-total/${filterData.posId}` + urlParams
			const temporaryTotalAmount = await GET(filterUrl)
			setTotalAmont(temporaryTotalAmount)
		}

		setDropdownIsOpen(false)
	}

	async function resetFilter() {
		var filterUrl = `/services/web/api/cheque-pageList/${filterData.posId}`

		setFilterData({
			...filterData,
			cashierId: null,
			currencyId: null,
			returned: null,
			paymentTypeId: null,
			startDate: null,
			endDate: null,
			search: ''
		})

		const response = await PGET(filterUrl, {}, { loader: true })
		if (response) {
			setTotalCount(response.headers['x-total-count'])
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response })
		}
		filterUrl = `/services/web/api/cheque-total/${filterData.posId}`
		const temporaryTotalAmount = await GET(filterUrl)
		setTotalAmont(temporaryTotalAmount)
		
	}
	

	async function getData() {
		const response = await PGET(`/services/web/api/cheque-pageList/${reduxAccount.posId}`, {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': `/services/web/api/cheque-pageList/${filterData.posId}`, 'response': response })
		}
	}

	async function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.cashierId === null &&
			filterData.currencyId === null &&
			filterData.returned === null &&
			filterData.startDate === null &&
			filterData.endDate === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		} else {
			setFilterDataExist(true)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
		}
	}

	async function getCashiers() {
		const response = await GET('/services/web/api/cashier-helper')
		if (response) {
			setCashiers(response)
		}
	}

	async function paginate(newPageData) {
		setData(newPageData)
	}

	useEffect(() => {
		getPoses()
		getCashiers()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<h4 className="vertical-center">
					{t('CHEQUES')} [{totalCount}]
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('POS')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.posId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('CASHIER')}</label>
												<Select
													options={cashiers}
													value={cashiers.find(option => option.id === filterData.cashierId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'cashierId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('CHOOSE_PAYMENT_TYPE')}</label>
												<Select
													options={paymentTypes}
													value={paymentTypes.find(option => option.id === filterData.paymentTypeId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'paymentTypeId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('CURRENCY')}</label>
												<Select
													options={currencies}
													value={currencies.find(option => option.id === filterData.currencyId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'currencyId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('STATUS')}</label>
												<Select
													options={statuses}
													value={statuses.find(option => option.id === filterData.returned) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'returned': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('PERIOD')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.startDate ? new Date(filterData.startDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'startDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.endDate ? new Date(filterData.endDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'endDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('APPLY')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button type="button" className="btn btn-outline-primary btn-wide" onClick={() => searchWithFilter(true)}>
									EXCEL
								</button>
							</div>
						</div>

						<div>
							<div className="d-flex justify-content-between">
								<div className="vertical-center">
									{totalAmount?.map((item, index) => (
										<React.Fragment key={index}>
											{item.currencyName === "USD" &&
												<div className="d-flex">
													<div className="w-100">
														<h5 className="text-end" key={index}>
															<div className="d-flex justify-content-between">
																<h5><b>{t('TOTAL_AMOUNT')}:</b></h5>
																{formatMoney(item.totalAmount)} {item.currencyName}
															</div>
															<div className="d-flex justify-content-between">
																<h5 className="me-3"><b>{t('TOTAL_AMOUNT_RETURN')}:</b></h5>
																{formatMoney(item.returnAmount)} {item.currencyName}
															</div>
														</h5>
													</div>
												</div>
											}

											{item.currencyName !== "USD" &&
												<div className="d-flex">
													<div>
														<h5 className="text-end" key={index}>
															<div className="d-flex justify-content-between">
																<h5><b>{t('TOTAL_AMOUNT')}:</b></h5>
																{formatMoney(item.totalAmount)} {item.currencyName}
															</div>
															<div className="d-flex justify-content-between">
																<h5 className="me-3"><b>{t('TOTAL_AMOUNT_RETURN')}:</b></h5>
																{formatMoney(item.returnAmount)} {item.currencyName}
															</div>
														</h5>
													</div>
												</div>
											}

										</React.Fragment>
									))}
								</div>
							</div>

						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{(filterData.startDate || filterData.endDate) &&
									<span className="me-2">
										<b>{t('PERIOD')}: </b>
										{filterData.startDate &&
											<span>{formatDate(filterData.startDate)}</span>
										}
										{(filterData.startDate && filterData.endDate) &&
											<span>|</span>
										}
										{filterData.endDate &&
											<span>{formatDate(filterData.endDate)}</span>
										}
									</span>
								}
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.cashierId &&
									<span className="me-2">
										<b>{t('CASHIER')}: </b> {findFromArrayById(cashiers, filterData.cashierId)}
									</span>
								}
								{filterData.paymentTypeId &&
									<span className="me-2">
										<b>{t('PAYMENT_METHOD')}: </b> {findFromArrayById(paymentTypes, filterData.paymentTypeId)}
									</span>
								}
								{(filterData.returned >= 0 && filterData.returned != null) &&
									<span className="me-2">
										<b>{t('STATUS')}: </b>
										{filterData.returned === 0 &&
											<span>{t('SUCCESS')}</span>
										}
										{findFromArrayById(statuses, filterData.returned)}
									</span>
								}
								{filterData.currencyId &&
									<span className="me-2">
										<b>{t('CURRENCY')}: </b> {findFromArrayById(currencies, filterData.currencyId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-hover mb-0">
							<thead>
								<tr>
									<th>{t('POS')}</th>
									<th className="text-center">{t('CASHBOX')}</th>
									<th className="text-center">{t('SHIFT_NUMBER')}</th>
									<th className="text-center">{t('CASHIER')}</th>
									<th className="text-center">{t('CHECK_NUMBER')}</th>
									<th className="text-center">{t('CLIENT')}</th>
									<th className="text-center">{t('ORGANIZATION')}</th>
									<th className="text-center">{t('CHEQUE_DATE')}</th>
									<th className="text-center">{t('SYNC_DATE')}</th>
									<th className="text-center">{t('STATUS')}</th>
									<th className="text-center">{t('TOTAL')}</th>
								</tr>
							</thead>
							<tbody>
								{data.length > 0 ? data.map((item, index) => (
									<tr className={'cursor ' + (item.showSidebar ? 'table-tr-active' : '')}
										key={index} onClick={() => toggleRightbar(true, item, index)}>
										<td>{item.rowNum}. {item.posName}</td>
										<td className="text-center">{item.cashboxName}</td>
										<td className="text-center">{item.shiftNumber}</td>
										<td className="text-center">{item.cashierName}</td>
										<td className="text-center">{item.chequeNumber}</td>
										<td className="text-center">{item.clientName}</td>
										<td className="text-center">{item.organizationName}</td>
										<td className="text-center">{formatDateWithTime(item.chequeDate)}</td>
										<td className="text-center">{formatDateWithTime(item.createdDate)}</td>
										<td className="text-center">
											{item.returned === 0 &&
												<span className="bg-success bg-border text-white">{t('SUCCESS')}</span>
											}
											{item.returned === 1 &&
												<span className="bg-warning bg-border text-white text-nowrap">{t('PARTIAL_RETURN')}</span>
											}
											{item.returned === 2 &&
												<span className="bg-danger bg-border text-white">{t('RETURN')}</span>
											}
										</td>
										<td className="text-center text-nowrap">
											<span className={item.clientAmount > 0 ? "text-danger" : ""} title={item.clientAmount > 0 ? t('SOLD_ON_CREDIT') : ""}>
												{formatMoney(item.totalPrice)}
											</span>
										</td>
									</tr>
								)): <tr>
										<td colSpan="10" className="text-center">{t('NO_DATA')}</td>
									</tr>

								}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			<Rightbar showRightBar={showRightBar} setShowRightBar={setShowRightBar} selectedItem={selectedItem}></Rightbar>
		</>
	)
}

export default Index
