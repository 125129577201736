import React, { useEffect, useState } from "react"
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import XLSX from 'xlsx'

import { Dropdown } from 'react-bootstrap'
import Select from 'react-select'
import FilterSearch from 'pages/parts/FilterSearch'
import Pagination , { ItemPerPage } from 'pages/parts/Pagination'
import { SET_REMEMBER_LAST_DOCUMENTIN_PREVIEW } from "store/settings/actions"

import { PGET, GET } from 'helpers/api'
import { checkRole, findFromArrayById, formatDate, formatDateWithTime, formatMoney } from 'helpers/helpers'
import useDidMountEffect from "pages/parts/useDidMountEffect"

function Index() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const reduxSettings = useSelector(state => state.settings)
	const reduxAccount = useSelector(state => state.account)
	const reduxFilterData = useSelector(state => state.filterData)

	const URL = `/services/web/api/documents-in-pageList`

	const canCreate = checkRole('POS_DOCUMENT_IN_ADD')

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [ItemsPerPage, setItemsPerPage] = useState(20)

	const [filterData, setFilterData] = useState({
		posId: reduxAccount.posId,
		search: '',
		page: 0,
		random: 0
	})
	const [pageCount, setPageCount] = useState(0)
	const [poses, setPoses] = useState([])
	const [data, setData] = useState([])

	async function exportToExcel(item) {
		const response = await GET('/services/web/api/documents-in/' + item.id + '?size=10000')

		var excelHeaders = [[t('POS'), t('SUPPLIER'), t('NAME_OF_PRODUCT'), t('BARCODE'), t('UNIT'), t('ADMISSION_PRICE'),
		t('SERIAL'), t('EXPIRATION_DATE'), t('QUANTITY'), t('WHOLESALE_PRICE'),
		t('UNIT_PRICE'), t('VAT'), t('AMOUNT')]]
		for (let i = 0; i < response.productList.content.length; i++) {
			var excelItems = []
			excelItems.push(item.posName)
			excelItems.push(item.organizationName)
			excelItems.push(response.productList.content[i].productName)
			excelItems.push(response.productList.content[i].barcode)
			excelItems.push(response.productList.content[i].uomName)
			excelItems.push(response.productList.content[i].price)
			excelItems.push(response.productList.content[i].serial)
			excelItems.push(formatDate(response.productList.content[i].expDate))
			excelItems.push(response.productList.content[i].quantity)
			excelItems.push(response.productList.content[i].wholesalePrice)
			excelItems.push(response.productList.content[i].salePrice)
			excelItems.push(response.productList.content[i].vat)
			excelItems.push(response.productList.content[i].totalAmount)
			excelHeaders.push(excelItems)
		}
		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('GOOD_RECEPTION') + ".xlsx");
	}

	function resetFilter() {
		setFilterData({
			...filterData,
			search: '',
			page: 0,
			random: filterData.random + 1,
		})
	}

	function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		} else {
			setFilterDataExist(true)
		}
	}

	async function getData() {
		checkFilter()
		const response = await PGET(URL, { ...filterData, size: ItemsPerPage }, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / ItemsPerPage))
			setData(response.data)
			setDropdownIsOpen(false)
		}
	}

	function redirectPreview(id) {
		dispatch(SET_REMEMBER_LAST_DOCUMENTIN_PREVIEW(id))
		navigate(`/documents-in/preview/${id}`)
	}

	async function paginate(data) {
		const response = await PGET(URL, { ...filterData, page: data.selected, size: ItemsPerPage }, { loader: true })
		setFilterData({ ...filterData, page: data.selected })
		setPageCount(Math.ceil(response.headers['x-total-count'] / ItemsPerPage))
		setData(response.data);
	}

	async function getPoses() {
		const response = await GET("/services/web/api/pos-helper")
		if (response) {
			response.unshift({ 'id': null, 'name': t('ALL') })
			setPoses(response)
		}
	}

	useEffect(() => {
		if (reduxFilterData?.documentsIn) {
			setFilterData({ ...filterData, ...reduxFilterData?.documentsIn, page: 0, random: filterData.random + 1 })
		} else {
			getData()
		}
		getPoses()
	}, [ItemsPerPage]) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		dispatch({ type: 'SET_FILTER_DATA', payload: { key: 'documentsIn', value: { ...filterData } } })
	}, [filterData]) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		getData()
	}, [filterData.random, ItemsPerPage]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('GOOD_RECEPTION')}
				</h4>
				<div className="d-flex">
					{canCreate &&
						<Link className="btn-rounded btn btn-primary" to="/documents-in/create">
							<i className="uil uil-plus me-2"></i>
							{t('CREATE')}
						</Link>
					}
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex">
						<div>
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}
								reduxFilterSearch={true}
							/>
						</div>
						<div>
							<Dropdown onToggle={() => setDropdownIsOpen(!dropdownIsOpen)} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('POS')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.posId) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button type="button" className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button type="button" className="btn btn-primary" onClick={() => setFilterData({ ...filterData, page: 0, random: filterData.random + 1 })}>
													{t('APPLY')}
												</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block my-3">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<ItemPerPage itemsPerPage={ItemsPerPage} 
						setItemsPerPage={(e) => {
							setItemsPerPage(e); // Update ItemsPerPage
							setFilterData({ ...filterData, page: 0, random: filterData.random + 1 }); // Reset to the first page
							getData(); // Fetch data with the new ItemsPerPage and page 0
					  	}}
					/>

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('POS')}</th>
									<th>{t('SUPPLIER')}</th>
									<th className="text-center">№ {t('DOCUMENT')}</th>
									<th className="text-center">{t('AMOUNT')}</th>
									<th className="text-center">{t('DATE_OF_RECEIPT')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.length > 0 ? data.map((item, index) => (
									<tr key={index}>
										<td>
											<span className={reduxSettings?.rememberLastDocumentInPreview === item.id ? 'border-bottom-primary' : ''}
												title={reduxSettings?.rememberLastDocumentInPreview === item.id ? t('LAST_SEEN_DOCUMENT') : ''}>
												{item.rowNum}. {item.posName}
											</span>
										</td>
										<td>{item.organizationName}</td>
										<td className="text-center">{item.inNumber}</td>
										<td className="text-center">{formatMoney(item.totalAmount)} {item.currencyName}</td>
										<td className="text-center">{formatDateWithTime(item.createdDate)}</td>
										<td className="d-flex justify-content-center">
											<div className="table-action-button table-action-primary-button me-2"
												onClick={() => redirectPreview(item.id)}>
												<i className="uil-eye"></i>
											</div>
											<div className="table-action-button table-action-success-button" onClick={() => exportToExcel(item)}>
												<i className="uil-file-alt"></i>
											</div>
										</td>
									</tr>
								)) : <tr>
										<td colSpan="10" className="text-center">{t('NO_DATA')}</td>
									</tr>
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate} reduxPage={filterData.page}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index
