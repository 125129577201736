import React, { Fragment, useEffect, useState } from "react"
import { Link, NavLink, useLocation, useNavigate, useNavigation } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'

import { showSidebar } from 'store/layout/actions'
//Import Scrollbar
import { Modal, Nav } from 'react-bootstrap'
import { exportMenu } from './menues/owner/menu'
import { exportMenuOrganization } from './menues/organization/menu'

import logo from "assets/images/logo.svg";
import TourExample from "Tour";

function SidebarContent() {
	const role = localStorage.getItem('role');

	const { t } = useTranslation()
	const location = useLocation()
	// const navigate = useNavigate()
	const dispatch = useDispatch()

	const reduxAccount = useSelector(state => state.account)

	const [menu, setMenu] = useState([])
	const [activeItemParent, setActiveItemParent] = useState(exportMenu[0].children[0])
	const [activeItem, setActiveItem] = useState(exportMenu[0])
	const navigate = useNavigate()

	function setActiveMenu(copyMenu) {
		var menuCopy = [...menu]
		if (copyMenu?.length > 0) {
			menuCopy = copyMenu
		}
		for (let k = 0; k < menuCopy.length; k++) {
			var grandParent = menuCopy[k]
			for (let i = 0; i < grandParent?.children?.length; i++) {
				var parent = grandParent?.children[i];
				for (let j = 0; j < parent?.children?.length; j++) {
					var child = parent?.children[j]
					if (location.pathname.includes(child?.url)) {
						child['active'] = true;
						setActiveItem(grandParent)
						setActiveItemParent(parent)
					} else {
						child['active'] = false;
					}
				}
			}
		}
		setMenu(menuCopy);
	}

	function setActiveGrandParent(item) {
		dispatch(showSidebar())
		var copy = { ...item }
		setActiveItem(copy)
		setActiveItemParent(copy?.children[0])
	}

	function setActiveParent(item) {

		// Barcha elementlarning `active` holatini false qilib chiqish
		const updatedChildren = item.children.map((child, index) => ({
			...child,
			active: index === 1, // Har doim birinchi element `active` bo'ladi
		}));

		// Yangilangan elementlar bilan nusxa olish
		const updatedItem = {
			...item,
			children: updatedChildren,
		};

		// Yangilangan elementni saqlash
		setActiveItemParent(updatedItem);
		// Birinchi elementning URL manziliga o'tish
		navigate(updatedChildren[1].url);
		document.title = `IDokon ${updatedChildren?.[1]?.t(item.i18n)}`
	}


	function setActiveChild(item) {

		var copy = { ...activeItemParent }
		for (let i = 0; i < copy?.children.length; i++) {
			copy.children[i].active = false
		}
		const index = copy?.children?.findIndex(x => x.url === item.url);
		console.log(item);

		document.title = `IDokon ${t(item.i18n)}`
		if (index !== -1 && copy?.children[index]) {
			copy.children[index].active = true
			setActiveItemParent(copy)
		}
	}

	function tToggle() {
		var body = document.body;
		body.classList.toggle("vertical-collpsed");
		body.classList.toggle("sidebar-enable");
	}

	function getMenu() {
		if (reduxAccount?.authorities) {
			var authorities = JSON.parse(JSON.stringify(reduxAccount?.authorities))
			var menuCopy = [...exportMenu]

			if (role === 'ROLE_ORGANIZATION') {
				menuCopy = exportMenuOrganization;
			}
			for (let k = 0; k < menuCopy.length; k++) {
				var grandParent = menuCopy[k]
				for (let i = 0; i < grandParent?.children?.length; i++) {
					var parent = grandParent?.children[i];
					for (let j = 0; j < parent?.children?.length; j++) {
						var child = parent?.children[j]
						for (let k = 0; k < authorities?.length; k++) {
							if (child['role'] === authorities[k] || child['title']) {
								child['show'] = true
								parent['show'] = true
							}
						}
					}

					if (parent['show']) {
						grandParent['show'] = true
					}
				}
			}
			var activeMenu = { children: [] };
			var activeMenuParent = { children: [] };
			var title = '';
			for (let i = 0; i < menuCopy.reverse().length; i++) {
				if (menuCopy[i].show) {
					activeMenu = menuCopy[i]
					activeMenuParent = menuCopy[i].children[0]
				}
			}
			setActiveItem(activeMenu)
			setActiveItemParent(activeMenuParent)
			setMenu([...menuCopy])

			setActiveMenu(menuCopy)
		}

	}
	const [show, setShow] = useState(false);


	useEffect(() => {
		setTimeout(() => {
			setShow(true)
		}, 1000);
		getMenu();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// useEffect(() => {
	// 	setActiveMenu()
	// }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="second-sidebar">
				<div className="second-sidebar-logo">
					<Link to="/documents-in"  >
						<img src={logo} alt="" width={120} />
					</Link>
				</div>

				{/* <button style={{ position: 'absolute', right: '0', top: '0' }}
					tabIndex="-1"
					onClick={() => {
						tToggle();
					}}
					type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn">
					<i className="uil-bars fz-20" />
				</button> */}
				{/* <TourExample  /> */}

				<div id="sidebar-menu">
					<Nav className="nav-child-mini" variant="tabs" activeKey={activeItemParent.icon}>
						{activeItem?.children?.map((item, index) => (
							item?.show &&
							<Nav.Item id={`step-${index + 1}`} onClick={() => setActiveParent(item)} key={index}>
								<Nav.Link eventKey={item.icon} tabIndex="-1">
									<span className="fw-bold"><i className={item.icon}></i></span>
								</Nav.Link>
							</Nav.Item>
						))}
					</Nav>

					{/* <div className="sidebar-title">
						{t(activeItemParent?.title)}
					</div> */}

					<ul className="metismenu list-unstyled" id="side-menu">
						{activeItemParent?.children?.map((item, index) => (
							<Fragment key={index}>
								{item.show && (
									item.title ? (
										<div id={`step-${index + 1}`} className="sidebar-title">
											{t(item.i18n)}
										</div>
									) : (
										<li className={item.active ? "mm-active d-flex menu-active" : "d-flex"}>
											<NavLink
												to={item.url}
												onClick={() => setActiveChild(item)}
												className={"waves-effect d-flex justify-content-between w-100" + (item.active ? " menu-active" : "")}
											>
												<div className="d-flex align-items-center">
													<i className={`${item.icon} fs-5`}></i>
													<div className="vertical-menu-div">{t(item.i18n)}</div>
												</div>
												{item.youtube_url && <YoutubePopover youtubeUrl={item.youtube_url} />}
											</NavLink>
										</li>
									)
								)}
							</Fragment>
						))}
					</ul>
				</div>
			</div>
		</>
	)
}

export default SidebarContent

function YoutubePopover({ youtubeUrl }) {
	return (
		<NavLink title="video qo'llanma" onClick={(e) => e.stopPropagation()} target="_blank" to={youtubeUrl} className="flex active">
			<svg xlinkTitle="video qo'llanma" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
				<path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
			</svg>
		</NavLink>
	);
}
