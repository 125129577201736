import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select, { components } from 'react-select'
import XLSX from 'xlsx';

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'
import TableControl from 'pages/parts/TableControl'

import { PGET, GET } from 'helpers/api'
import { formatMoney, returnSign, formatDateBackend, findFromArrayById, formatDate, formatDateWithTime } from 'helpers/helpers'
import Pagination, { ItemPerPage } from "pages/parts/Pagination"

function Index() {
	const { t } = useTranslation()
	const date = new Date()

	const reduxSettings = useSelector(state => state.settings)
	const reduxAccount = useSelector(state => state.account)

	const seasons = [{ id: 0, name: t('EMPTY') }, { id: 1, name: t('SEASONAL') }, { id: 2, name: t('NOT_SEASONAL') }];

	const [categoriesInitial, setCategoriesInitial] = useState([])
	const [poses, setPoses] = useState([])
	const [organiations, setOrganizations] = useState([])
	const [categories, setCategories] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [totalAmount, setTotalAmont] = useState([])
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const [ItemsPerPage, setItemsPerPage] = useState(20)
	const [pageCount, setPageCount] = useState(0)
	const [URL, setURL] = useState("");

	const [filterData, setFilterData] = useState({
		startDate: formatDateBackend(new Date(date.getFullYear(), date.getMonth(), 1)),
		endDate: formatDateBackend(new Date()),
		posId: reduxAccount.posId,
		organizationId: null,
		productCategoryId: null,
		groupBy: false,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/services/web/api/report-documentIn',
		response: null
	})
	const [data, setData] = useState([])
	const [modalTableControl, setModalTableControl] = useState(false)

	async function searchWithFilter(exportToExcel = false, tableSort = "") {
		var filterUrl = "/services/web/api/report-documentIn"
		var urlParams = ""
		checkFilter()

		if (filterData.startDate)
			urlParams += returnSign(urlParams) + 'startDate=' + filterData.startDate
		if (filterData.endDate)
			urlParams += returnSign(urlParams) + 'endDate=' + filterData.endDate
		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.organizationId)
			urlParams += returnSign(urlParams) + 'organizationId=' + filterData.organizationId
		if (filterData.productCategoryId)
			urlParams += returnSign(urlParams) + 'productCategoryId=' + filterData.productCategoryId
		if (filterData?.seasonal)
			urlParams += returnSign(urlParams) + 'seasonal=' + filterData?.seasonal
		if (filterData.groupBy)
			urlParams += returnSign(urlParams) + 'groupBy=' + filterData.groupBy
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if ("/services/web/api/report-documentIn" + urlParams !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		if (tableSort) {
			var filterDataCopy = { ...filterData }
			if (filterDataCopy.tableSortKey === tableSort) {
				filterDataCopy.tableSortDirection === ',asc' ? filterDataCopy.tableSortDirection = ',desc' : filterDataCopy.tableSortDirection = ',asc'
			} else {
				filterDataCopy.tableSortDirection = ',asc'
			}
			filterDataCopy.tableSortKey = tableSort

			setFilterData(filterDataCopy)
			urlParams += returnSign(urlParams) + 'sort=' + tableSort + filterDataCopy.tableSortDirection
		}
		let WithoutParams = filterUrl
		filterUrl = filterUrl + urlParams
		setURL(filterUrl);

		if (exportToExcel) {
			filterUrl = "/services/web/api/report-documentIn-excel" + urlParams + returnSign(filterUrl)

			var excelHeaders = [[t('POS'), t('PRODUCT_ID'), t('NAME_OF_PRODUCT'), t('SUPPLIER'), t('BARCODE'), t('ARTIKUL'), t('CATEGORY'), t('QUANTITY'), t('UNIT'),
			t('ADMISSION_PRICE'), t('BANK_PRICE'), t('CURRENCY'), t('VAT'), t('AMOUNT') + ' ' + t('VAT'), t('SALE_PRICE'), t('EXPENSES'), t('DISPLACEMENT'), t('RECEIPT_DATE'), t('TOTAL_AMOUNT')]]
			const response = await GET(filterUrl)
			for (let i = 0; i < response.length; i++) {
				var excelItems = []
				excelItems.push(response[i].posName)
				excelItems.push(response[i].productId)
				excelItems.push(response[i].productName)
				excelItems.push(response[i].organizationName)
				excelItems.push(response[i].productBarcode)
				excelItems.push(response[i].productArtikul)
				excelItems.push(response[i].categoryName)
				excelItems.push(response[i].quantity)
				excelItems.push(response[i].uomName)
				excelItems.push(response[i].price)
				excelItems.push(response[i].bankPrice)
				excelItems.push(response[i].currencyName)
				excelItems.push(response[i].vat)
				excelItems.push(response[i].vatAmount)
				excelItems.push(response[i].salePrice)
				excelItems.push(formatMoney(response[i].expense))
				excelItems.push(response[i].activated ? t('YES') : t('NO'))
				excelItems.push(formatDateWithTime(response[i].createdDate))
				excelItems.push(response[i].totalAmount)
				excelItems.push(response[i].deleted ? t('DELETED') : '')
				excelHeaders.push(excelItems)
			}
			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('INCOME_REPORT') + ".xlsx");
		} else {
			const response = await PGET(WithoutParams, { ...filterData, size: ItemsPerPage }
				, { loader: true })
			if (response) {
				setData(response.data)
				setPagination({ ...pagination, 'url': filterUrl, 'response': response })
				setPageCount(Math.ceil(response.headers['x-total-count'] / ItemsPerPage))
			}
			filterUrl = "/services/web/api/report-documentIn-total" + urlParams
			const temporaryTotalAmount = await GET(filterUrl)
			setTotalAmont(temporaryTotalAmount)
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			startDate: null,
			endDate: null,
			posId: null,
			organizationId: null,
			productCategoryId: null,
			groupBy: false,
			search: '',
		})
		setFilterDataExist(false)
		getData()
	}

	async function getData() {
		const response = await PGET(URL, { ...filterData, size: ItemsPerPage }
			, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': URL, 'response': response })
			setPageCount(Math.ceil(response.headers['x-total-count'] / ItemsPerPage))
		}
		const response2 = await GET("/services/web/api/report-documentIn-total", {}, { loader: false })
		if (response2) {
			setTotalAmont(response2)
		}
	}

	function checkFilter() {
		if (
			filterData.startDate === null &&
			filterData.endDate === null &&
			filterData.posId === null &&
			filterData.organizationId === null &&
			filterData.groupBy === false &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			response.unshift({ 'id': null, 'name': t('ALL') })
			setPoses(response)
		}
	}

	async function getOrganizations() {
		const response = await GET('/services/web/api/organization-helper')
		if (response) {
			setOrganizations(response)
		}
	}

	async function getCategories() {
		const response = await GET('/services/web/api/product-category-helper')
		var arr = []
		for (let i = 0; i < response.length; i++) {
			response[i]['label'] = ' ' + response[i]['name']
			response[i].child = false;
			if (response[i].parentId !== 0) {
				const index = response.findIndex(x => x.id === response[i].parentId)
				response[i].child = true;
				if (response[index].options && response[index].options.length > 0) {
					response[index].options.push(response[i])
				} else {
					response[index].options = [response[i]]
				}
			} else {
				arr.push(response[i])
			}
		}
		setCategories(arr)
		setCategoriesInitial(response)
	}

	async function paginate(data) {
		const response = await PGET(URL, { ...filterData, page: data.selected, size: ItemsPerPage }, { loader: true })
		setFilterData({ ...filterData, page: data.selected })
		setPageCount(Math.ceil(response.headers['x-total-count'] / ItemsPerPage))
		setData(response.data);
	}

	useEffect(() => {
		getPoses()
		getOrganizations()
		getCategories()
	}, [ItemsPerPage]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search, ItemsPerPage]) // eslint-disable-line react-hooks/exhaustive-deps

	const GroupHead = props => {
		return (
			<div style={{
				padding: '0',
				color: filterData.productCategoryId === props.data.id ? '#fff' : '#999',
				fontWeight: 'bold',
				backgroundColor: filterData.productCategoryId === props.data.id ? '#2684FF' : '#fff',
				fontSize: '.8rem'
			}}
				onClick={(e) => selectCategory(props.data)}>
				<components.Option {...props} />
			</div>
		);
	};

	const Option = props => {
		return (
			<div style={{
				padding: props.data.child ? '0 0 0 20px' : '0', color: '#999',
				fontWeight: props.data.parentId === 0 ? 'bold' : 'normal', fontSize: '.8rem'
			}}>
				<components.Option {...props} />
			</div>
		);
	};

	async function selectCategory(option) {
		setIsOpen(!isOpen)
		if (option === null) {
			getData()
			setFilterData({ ...filterData, 'productCategoryId': null })
			return
		}
		setFilterData({ ...filterData, 'productCategoryId': option.id })
	}

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('INCOME_REPORT')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('POS')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.posId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('SUPPLIER')}</label>
												<Select
													options={organiations}
													value={organiations.find(option => option.id === filterData.organizationId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'organizationId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('CATEGORY')}</label>
												<Select
													isClearable
													components={{ GroupHeading: GroupHead, Option }}
													classNamePrefix="react-select"
													options={categories}
													value={categoriesInitial.find(option => option.id === filterData.productCategoryId) || ''}
													onChange={(option) => selectCategory(option)}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.label}
													getOptionValue={(option) => option.id}
													onMenuOpen={() => setIsOpen(true)}
													onBlur={() => setIsOpen(false)}
													menuIsOpen={isOpen}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('SEASONALITY')}</label>
												<Select
													isClearable
													classNamePrefix="react-select"
													options={seasons}
													value={seasons.find(option => option.id === filterData.seasonal || '')}
													onChange={(option) => setFilterData({ ...filterData, 'seasonal': option?.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('PERIOD')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.startDate ? new Date(filterData.startDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'startDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.endDate ? new Date(filterData.endDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'endDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-6">
												<div className="d-flex justify-content-between mb-2">
													<p className="fw-600 vertical-center">{t('GROUPING')}</p>
													<div className="form-check form-switch form-switch-lg">
														<input type="checkbox" className="form-check-input"
															checked={filterData.groupBy}
															onChange={(e) => setFilterData({ ...filterData, 'groupBy': e.target.checked })} />
													</div>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('APPLY')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide" onClick={() => searchWithFilter(true)}>EXCEL</button>
							</div>
						</div>
						<div className="d-flex">
							<div className="vertical-center me-2">
								<h4>
									<b>{t('TOTAL_AMOUNT')}</b>:
								</h4>
							</div>
							<div className="vertical-center">
								{totalAmount?.map((item, index) => (
									<h5 className="text-end" key={index}>
										<span className="me-1">{formatMoney(item.totalAmount)}</span>
										<b>{item.currencyName}</b>
									</h5>
								))
								}
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{(filterData.startDate || filterData.endDate) &&
									<span className="me-2">
										<b>{t('PERIOD')}: </b>
										{filterData.startDate &&
											<span>{formatDate(filterData.startDate)}</span>
										}
										{(filterData.startDate && filterData.endDate) &&
											<span>|</span>
										}
										{filterData.endDate &&
											<span>{formatDate(filterData.endDate)}</span>
										}
									</span>
								}
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.organizationId &&
									<span className="me-2">
										<b>{t('SUPPLIER')}: </b> {findFromArrayById(organiations, filterData.organizationId)}
									</span>
								}
								{filterData.productCategoryId &&
									<span className="me-2">
										<b>{t('CATEGORY')}: </b> {findFromArrayById(categories, filterData.productCategoryId)}
									</span>
								}
								{filterData.seasonal &&
									<span className="me-2">
										<b>{t('SEASONALITY')}: </b> {findFromArrayById(seasons, filterData.seasonal)}
									</span>
								}
								{filterData.groupBy &&
									<span className="me-2">
										<b>{t('GROUPING')}: </b> {t('ACTIVATED')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}
					<ItemPerPage
						itemsPerPage={ItemsPerPage}
						setItemsPerPage={(e) => {
							setItemsPerPage(e); // Update ItemsPerPage
							setFilterData({ ...filterData, page: 0, random: filterData.random + 1 }); // Reset to the first page
						}}
					/>

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									{(!reduxSettings.tableReportIncomes || reduxSettings.tableReportIncomes?.pos) &&
										<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'posName')}>
											{t('POS')}
											<div className="table-sort">
												<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'posName') && 'sort-active')}></i>
												<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'posName') && 'sort-active')}></i>
											</div>
										</th>
									}
									<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'productName')}>
										{t('NAME_OF_PRODUCT')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'productName') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'productName') && 'sort-active')}></i>
										</div>
									</th>
									{(!reduxSettings.tableReportIncomes || reduxSettings.tableReportIncomes?.categoryName) &&
										<th className="th-sortable text-center cursor" onClick={() => searchWithFilter(false, 'categoryName')}>
											{t('CATEGORY')}
											<div className="table-sort">
												<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'categoryName') && 'sort-active')}></i>
												<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'categoryName') && 'sort-active')}></i>
											</div>
										</th>
									}
									<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'organizationName')}>
										{t('SUPPLIER')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'organizationName') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'organizationName') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable cursor text-center" onClick={() => searchWithFilter(false, 'productBarcode')}>
										{t('BARCODE')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'productBarcode') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'productBarcode') && 'sort-active')}></i>
										</div>
									</th>
									{(!reduxSettings.tableReportIncomes || reduxSettings.tableReportIncomes?.artikul) &&
										<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'productArtikul')}>
											{t('ARTIKUL')}
											<div className="table-sort">
												<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'productArtikul') && 'sort-active')}></i>
												<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'productArtikul') && 'sort-active')}></i>
											</div>
										</th>
									}
									<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'quantity')}>
										{t('QUANTITY')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'quantity') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'quantity') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable cursor text-center" onClick={() => searchWithFilter(false, 'price')}>
										{t('ADMISSION_PRICE')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'price') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'price') && 'sort-active')}></i>
										</div>
									</th>
									{(!reduxSettings.tableReportIncomes || reduxSettings.tableReportIncomes?.wholesalePrice) &&
										<th className="th-sortable cursor text-center" onClick={() => searchWithFilter(false, 'wholesalePrice')}>
											{t('WHOLESALE_PRICE')}
											<div className="table-sort">
												<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'wholesalePrice') && 'sort-active')}></i>
												<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'wholesalePrice') && 'sort-active')}></i>
											</div>
										</th>
									}
									{(!reduxSettings.tableReportIncomes || reduxSettings.tableReportIncomes?.bankPrice) &&
										<th className="th-sortable cursor text-center" onClick={() => searchWithFilter(false, 'bankPrice')}>
											{t('BANK_PRICE')}
											<div className="table-sort">
												<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'bankPrice') && 'sort-active')}></i>
												<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'bankPrice') && 'sort-active')}></i>
											</div>
										</th>
									}
									{(!reduxSettings.tableReportIncomes || reduxSettings.tableReportIncomes?.currencyName) &&
										<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'currencyName')}>
											{t('CURRENCY')}
											<div className="table-sort">
												<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'currencyName') && 'sort-active')}></i>
												<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'currencyName') && 'sort-active')}></i>
											</div>
										</th>
									}
									{(!reduxSettings.tableReportIncomes || reduxSettings.tableReportIncomes?.vat) &&
										<th className="text-center">{t('VAT')}</th>
									}
									{(!reduxSettings.tableReportIncomes || reduxSettings.tableReportIncomes?.vatAmount) &&
										<th className="text-center">{t('AMOUNT')} {t('VAT')}</th>
									}
									<th className="th-sortable cursor text-center" onClick={() => searchWithFilter(false, 'salePrice')}>
										{t('SALE_PRICE')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'salePrice') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'salePrice') && 'sort-active')}></i>
										</div>
									</th>
									{(!reduxSettings.tableReportIncomes || reduxSettings.tableReportIncomes?.expense) &&
										<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'expense')}>
											{t('EXPENSES')}
											<div className="table-sort">
												<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'expense') && 'sort-active')}></i>
												<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'expense') && 'sort-active')}></i>
											</div>
										</th>
									}
									{(!reduxSettings.tableReportIncomes || reduxSettings.tableReportIncomes?.transfer) &&
										<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'transfer')}>
											{t('DISPLACEMENT')}
											<div className="table-sort">
												<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'transfer') && 'sort-active')}></i>
												<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'transfer') && 'sort-active')}></i>
											</div>
										</th>
									}
									<th className="th-sortable cursor text-center" onClick={() => searchWithFilter(false, 'createdDate')}>
										{t('RECEIPT_DATE')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'createdDate') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'createdDate') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable cursor text-center" onClick={() => searchWithFilter(false, 'totalAmount')}>
										<i className="uil-setting table-control-icon" onClick={() => setModalTableControl(true)}></i>
										{t('TOTAL_AMOUNT')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'totalAmount') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'totalAmount') && 'sort-active')}></i>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										{(!reduxSettings.tableReportIncomes || reduxSettings.tableReportIncomes?.pos) &&
											<td>{item.rowNum}. {item.posName}</td>
										}
										<td >{item.productName}</td>
										{(!reduxSettings.tableReportIncomes || reduxSettings.tableReportIncomes?.categoryName) &&
											<td className="text-end">{item.categoryName}</td>
										}
										<td>{item.organizationName}</td>
										<td className="text-end">{item.productBarcode}</td>
										{(!reduxSettings.tableReportIncomes || reduxSettings.tableReportIncomes?.artikul) &&
											<td className="text-end">{item.productArtikul}</td>
										}
										<td className="text-end">{formatMoney(item.quantity)} {item.uomName}</td>
										<td className="text-end text-nowrap">{formatMoney(item.price)}</td>
										{(!reduxSettings.tableReportIncomes || reduxSettings.tableReportIncomes?.wholesalePrice) &&
											<td className="text-end text-nowrap">{formatMoney(item.wholesalePrice)}</td>
										}
										{(!reduxSettings.tableReportIncomes || reduxSettings.tableReportIncomes?.bankPrice) &&
											<td className="text-end text-nowrap">{formatMoney(item.bankPrice)}</td>
										}
										{(!reduxSettings.tableReportIncomes || reduxSettings.tableReportIncomes?.currencyName) &&
											<td>{item.currencyName}</td>
										}
										{(!reduxSettings.tableReportIncomes || reduxSettings.tableReportIncomes?.vat) &&
											<td className="text-end">{item.vat}</td>
										}
										{(!reduxSettings.tableReportIncomes || reduxSettings.tableReportIncomes?.vatAmount) &&
											<td className="text-end text-nowrap">{formatMoney(item.vatAmount)}</td>
										}
										<td className="text-end text-nowrap">{formatMoney(item.salePrice)}</td>
										{(!reduxSettings.tableReportIncomes || reduxSettings.tableReportIncomes?.expense) &&
											<td className="text-end text-nowrap">{formatMoney(item.expense ?? 0)}</td>
										}
										{(!reduxSettings.tableReportIncomes || reduxSettings.tableReportIncomes?.transfer) &&
											<td className="text-center">
												{
													item.activated ?
														<span className="bg-primary bg-border text-white">{t('YES')}</span>
														:
														<span className="bg-danger bg-border text-white">{t('NO')}</span>
												}
											</td>
										}
										<td className="text-center text-nowrap">
											{item.deleted ?
												<Link to={'/documents-in/preview/' + item.id} className="bg-danger bg-border text-white cursor">
													{formatDateWithTime(item.createdDate)}
												</Link>
												:
												<>{formatDateWithTime(item.createdDate)}</>
											}
										</td>
										<td className="text-end text-nowrap">{formatMoney(item.totalAmount)}</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>
					<Pagination pageCount={pageCount} paginate={paginate} reduxPage={filterData.page}></Pagination>
				</div>
			</div>

			{/* MODAL TABLE CONTROL */}
			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'tableReportIncomes'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}
		</>
	)
}

export default Index
