import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { SET_UNLOCK_SCREEN } from 'store/settings/actions'
import { HashRouter as Router } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import Index from './routes/index'
import logo from './assets/images/logo.svg'
// Import scss
//import "./assets/scss/theme.scss";'
import './assets/css/iconscout.css'
import './assets/css/bootstrap.css'
import './assets/css/bootstrap-override.css'
import './assets/css/topbar.css'
import './assets/css/vertical.css'
import './assets/css/custom-scrollbar.css'
import './assets/css/global.css'
import './assets/css/datepicker.css'
import './assets/css/forms.css'
import "./assets/css/react-datepicker.css"
import "./assets/css/tag.css"
import "./assets/css/dark.css"

function App() {
	const { t } = useTranslation()
	const dispatch = useDispatch();

	const reduxSettings = useSelector(state => state.settings)
	const loader = useSelector(state => state.loader)
	const lockScreen = useSelector(state => state.settings.lockScreen)

	const [password, setPassword] = useState('')

	function unlockScreen(e) {
		e.preventDefault();

		if (JSON.parse(localStorage.getItem('authUser'))['password'] === password) {
			dispatch(SET_UNLOCK_SCREEN())
			setPassword("")
		} else {
			toast.error(t('INVALID_PASSROD'))
		}
	}

	useEffect(() => {
		document.addEventListener("wheel", function () {
			if (document.activeElement.type === "number") {
				document.activeElement.blur();
			}
		});
		document.addEventListener("keypress", function (event) {
			var invalidChars = ["+", "e"];
			if (document.activeElement.type === "number" && invalidChars.includes(event.key)) {
				event.preventDefault();
			}
		});
	}, [])

	useEffect(() => {
		var bodyElement = document.body;
		bodyElement.setAttribute("class", reduxSettings.theme ? "dark" : "light");
	}, [reduxSettings.theme])

	return (
		<>
			<ToastContainer
				position="bottom-left"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick={false}
				theme="colored"
			/>

			{loader &&
				<div id="loading-bg">
					<div className="loading">
						<img src={logo} alt="logo" className="loading-logo" />
						<div class="loading-dots">
							<div class="dot"></div>
							<div class="dot"></div>
							<div class="dot"></div>
							<div class="dot"></div>
						</div>
					</div>
				</div>
			}

			{lockScreen &&
				<div className="auth-overlay-wrapper">
					<div className="auth-overlay-wrapper2">
						<div className="auth-overlay-wrapper3">
							<div className="card">
								<div className="card-body">
									<form onSubmit={(e) => unlockScreen(e)} autoComplete="off">
										<div className=" text-center">
											<div className="d-flex mb-2 justify-content-center">
												<h3>{t('LOGGING_IN')}</h3>
											</div>
										</div>
										<div className="form-group mb-2">
											<label>{t('PASSWORD')}<span className="required-mark">*</span></label>
											<input type="password" className="form-control" placeholder={t('PASSWORD')}
												autoFocus value={password} onChange={(e) => setPassword(e.target.value)} />
										</div>
										<button button="submit" className="btn btn-primary w-100">{t('ENTER')}</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			}

			<Router>
				<Index />
			</Router>
		</>
	);
};

export default App;