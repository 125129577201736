import React, { useEffect, useState } from "react"
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { format } from 'date-fns'
import Select from 'react-select'
import XLSX from 'xlsx';

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'
import TableControl from 'pages/parts/TableControl'

import { PGET, GET, DELETE } from 'helpers/api'
import { formatMoney, findIdFromArray, checkRole } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const reduxSettings = useSelector(state => state.settings)
	const reduxAccount = useSelector(state => state.account)

	const canCreate = checkRole('POS_SCALE_ADD')
	const canDelete = checkRole('POS_SCALE_DELETE')

	const [pagination, setPagination] = useState({
		url: `/services/web/api/scales-pageList/${reduxAccount.posId}`,
		response: null
	})
	const [pageCount, setPageCount] = useState(0)
	const [poses, setPoses] = useState([])
	const [posId, setPosId] = useState(reduxAccount.posId)
	const [data, setData] = useState([])
	const [modalTableControl, setModalTableControl] = useState(false)

	async function handleSelect(option) {
		setPagination({ ...pagination, 'url': `/services/web/api/scales-pageList/${option.id}` })
		const response = await PGET(`/services/web/api/scales-pageList/${option.id}`, {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / reduxSettings.size))
			setData(response.data)
			setPosId(option.id)
		}
	}

	async function deleteItem(item, index) {
		const response = await DELETE('/services/web/api/scales/' + item.id, { loader: true })

		if (response) {
			getData()
		}
	}

	async function deleteAll() {
		await DELETE('/services/web/api/scales-all/' + posId, { loader: true })
		getData()
	}

	async function getData() {
		const temporaryPoses = await GET("/services/web/api/pos-helper", {}, { loader: true })
		setPoses(temporaryPoses)

		var temporaryPosId;
		if (reduxSettings.posId && findIdFromArray(temporaryPoses, reduxSettings.posId)) {
			setPosId(reduxSettings.posId)
			temporaryPosId = reduxSettings.posId
		} else {
			setPosId(reduxAccount.posId)
			temporaryPosId = reduxAccount.posId
		}


		setPagination({ ...pagination, 'url': `/services/web/api/scales-pageList/${temporaryPosId}` })
		const response = await PGET(`/services/web/api/scales-pageList/${temporaryPosId}`, {}, { loader: true })
		if (response && response.data) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / reduxSettings.size))
			setData(response.data)
		}
	}

	async function exportToScaleSM100() {
		const response = await GET("/services/web/api/scales-list/" + posId)
		var txt = ""

		for (let i = 0; i < response.length; i++) {
			txt += "A;";
			txt += response[i]['barcodeScales'] + ";";
			switch (response[i]['barcodeScales'].length) {
				case 1:
					txt += "200000" + response[i]['barcodeScales']
					break;
				case 2:
					txt += "20000" + response[i]['barcodeScales']
					break;
				case 3:
					txt += "2000" + response[i]['barcodeScales']
					break;
				case 4:
					txt += "200" + response[i]['barcodeScales']
					break;
				case 5:
					txt += "20" + response[i]['barcodeScales']
					break;
				case 6:
					txt += "2" + response[i]['barcodeScales']
					break;
				default:
					txt += response[i]['barcodeScales']
			}
			txt += ";";
			txt += formatMoney((response[i]['salePrice'] / 100), 2, ',', '') + ";";
			txt += "0;";
			txt += "0;";
			txt += "0;";
			txt += response[i]['productName'];
			txt += "\r\n";
		}
		var FileSaver = require('file-saver');
		var iconv = require('iconv-lite');
		var buf = iconv.encode(txt, 'win1251');
		FileSaver.saveAs(new Blob([buf], { type: 'text/plain;charset=windows-1251' }), "180.csv");
	}

	async function exportToScaleTM() {
		const response = await GET("/services/web/api/scales-list/" + posId)
		var txt = ""
		txt += "\r\n";
		txt += "ECS	VER	100 ";
		txt += "\r\n";
		txt += "CLR	PLU	";
		txt += "\r\n";
		txt += "CLR	END	";
		txt += "\r\n";
		txt += "DWL	PLU	";
		txt += "\r\n";
		for (let i = 0; i < response.length; i++) {
			txt += "PLU	";
			txt += i + 1 + "	";
			switch (response[i]['barcodeScales'].length) {
				case 1:
					txt += "00000" + response[i]['barcodeScales']
					break;
				case 2:
					txt += "0000" + response[i]['barcodeScales']
					break;
				case 3:
					txt += "000" + response[i]['barcodeScales']
					break;
				case 4:
					txt += "00" + response[i]['barcodeScales']
					break;
				case 5:
					txt += "0" + response[i]['barcodeScales']
					break;
				default:
					txt += response[i]['barcodeScales']
			}
			txt += "		";
			txt += "3";
			txt += "	";
			txt += response[i]['salePrice'] + "	";
			txt += "0,0	0,0	0	0	20	0	0	0	9	";
			txt += response[i]['productName'] + "			";
			txt += "0	0	0	0	0	0	0	0	0	0	0	0	0,0	0,0	0	127	0,0	0,0	0,0	0	127	0,0	0,0	0,0	0	127	0,0	0,0	0,0	0	127	0,0	0,0	0,0	0	0	0	0	0	0	0	";
			txt += response[i]['productName'] + "	0	0	0	";
			txt += "\r\n";
		}
		txt += "END	PLU	";
		txt += "\r\n";
		txt += "END	ECS	";
		txt += "\r\n";

		var FileSaver = require('file-saver');
		var iconv = require('iconv-lite');
		var buf = iconv.encode(txt, 'utf-8');
		FileSaver.saveAs(new Blob([buf], { type: 'text/plain;charset=UTF-8' }), "A_xxx.TMS");
	}

	async function exportToScale() {
		const response = await GET("/services/web/api/scales-list/" + posId)
		var txt = ""
		for (let i = 0; i < response.length; i++) {
			txt += i + 1 + ";"; // №
			txt += response[i]['productName'] + ";"; // Наименование
			txt += ";"; // Наименование2
			txt += response[i]['salePrice'].toString() + ";"; // Цена
			txt += "0;"; // Срок годности
			txt += "0;"; // Тара
			txt += "0;"; // Групповой код
			txt += response[i]['barcodeScales'] + ";"; // Код товара
			txt += "0;"; // № сообзения
			txt += "0;"; // Изображения
			txt += ";"; // Код PROTECT
			txt += format(new Date(), 'dd.MM.yy'); // Дата реализации
			txt += ";"; // Дата реализации
			txt += response[i]['productUomName'] === "шт" ? "1;" : "0;"; // Тип товара
			txt += response[i].productUomName === "шт" || response[i].productUomName === "dona" ? "1;" : "0;"; // Структура ШК
			txt += response[i].productUomName === "шт" || response[i].productUomName === "dona" ? "1;" : "0;"; // Формат этикетки
			txt += response[i].productUomName === "шт" || response[i].productUomName === "dona" ? "1;" : "0;"; // Тип префикса
			txt += format(new Date(), 'dd.MM.yy'); // Дата изготовления
			txt += "\r\n";
		}

		var FileSaver = require('file-saver');
		var iconv = require('iconv-lite');
		var buf = iconv.encode(txt, 'win1251');
		FileSaver.saveAs(new Blob([buf], { type: 'text/plain;charset=windows-1251' }), "wares_export.txt");
	}

	async function exportToRongta() {
		const response = await GET("/services/web/api/scales-list/" + posId)
		var txt = ""
		for (let i = 0; i < response.length; i++) {
			txt += response[i]['barcodeScales'] + ";"; // Код товара
			txt += response[i]['productName'] + ";"; // Наименование
			txt += ";"; // Наименование2
			txt += response[i]['salePrice'].toString().replace(".", ",") + ";"; // Цена
			txt += "0;"; // Срок годности
			txt += "0;"; // Тара
			txt += "0;"; // Групповой код
			txt += response[i]['barcodeScales'] + ";"; // Код товара
			txt += "0;"; // № сообзения
			txt += "0;"; // Изображения
			txt += ";"; // Код PROTECT
			txt += format(new Date(), 'dd.MM.yy'); // Дата реализации
			txt += ";"; // Дата реализации
			txt += response[i]['productUomName'] === "шт" ? "1;" : "0;"; // Тип товара
			txt += "0;"; // Структура ШК
			txt += "0;"; // Формат этикетки
			txt += "0;"; // Тип префикса
			txt += format(new Date(), 'dd.MM.yy'); // Дата изготовления
			txt += "\r\n";
		}

		var FileSaver = require('file-saver');
		var iconv = require('iconv-lite');
		var buf = iconv.encode(txt, 'win1251');
		FileSaver.saveAs(new Blob([buf], { type: 'text/plain;charset=windows-1251' }), "wares_export.txt");
	}

	async function redirectToPriceTagsPrint() {
		// const response = await PGET(pagination.url, { posId: posId }, { loader: true })
		// console.log(response.data);
		var dataCopy = [...data]
		for (let i = 0; i < dataCopy.length; i++) {
			dataCopy[i]['barcode'] = dataCopy[i]['productBarcode']
		}
		dispatch({ type: 'SET_DOCUMENT_IN_PRODUCT_LIST', payload: dataCopy })
		navigate('/price-tags')
	}

	async function exportToExcel() {
		const response = await GET("/services/web/api/scales-list/" + posId)
		var excelHeaders = [[t('POS'), t('NAME_OF_PRODUCT'), t('BARCODE'), t('BARCODE_FOR_SCALE'), t('SALE_PRICE')]]
		for (let i = 0; i < response.length; i++) {
			var excelItems = []
			excelItems.push(response[i].posName)
			excelItems.push(response[i].productName)
			excelItems.push(response[i].productBarcode)
			excelItems.push(response[i].barcodeScales)
			excelItems.push(response[i].salePrice)
			excelHeaders.push(excelItems)
		}
		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, "wares_export.xlsx");
	}

	async function paginate(data) {
		const response = await PGET(pagination.url, { page: data.selected, size: reduxSettings.size })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('SCALE')}
				</h4>
				<div className="d-flex">
					<div className="btn btn-primary me-2" onClick={() => redirectToPriceTagsPrint()}>
						<i className="uil uil-print me-2"></i>
						{t('PRINT')}
					</div>
					<DropdownButton title="Действие" className="me-2">
						<Dropdown.Item onClick={exportToScale}>
							{t('EXPORT_TO_SCALE')} ШТРИХ-М
						</Dropdown.Item>
						<Dropdown.Item onClick={exportToScaleSM100}>
							{t('EXPORT_TO_SCALE')} SM-100
						</Dropdown.Item>
						<Dropdown.Item onClick={exportToScaleTM}>
							{t('EXPORT_TO_SCALE')} TM-*
						</Dropdown.Item>
						<Dropdown.Item onClick={exportToRongta}>
							{t('EXPORT_TO_SCALE')} RONGTA
						</Dropdown.Item>
						<Dropdown.Item onClick={exportToExcel}>
							{t('EXPORT_TO_EXEL')}
						</Dropdown.Item>
						<Dropdown.Item onClick={deleteAll}>
							<span className="text-danger">{t('DELETE_ALL')}</span>
						</Dropdown.Item>
					</DropdownButton>

					{canCreate &&
						<Link className="btn-rounded btn btn-primary" to="/scale/create">
							<i className="uil uil-plus me-2"></i>
							{t('CREATE')}
						</Link>
					}
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<Search url={pagination.url} search={search} getData={getData}></Search>
						<div className="w-25">
							<Select
								options={poses}
								value={poses.find(option => option.id === posId)}
								onChange={handleSelect}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									{(!reduxSettings.tableScale || reduxSettings.tableScale?.pos) &&
										<th>{t('POS')}</th>
									}
									<th>{t('NAME_OF_PRODUCT')}</th>
									<th className="text-center">{t('UNIT')}</th>
									<th className="text-center">{t('BARCODE')}</th>
									{(!reduxSettings.tableScale || reduxSettings.tableScale?.artikul) &&
										<th className="text-center">{t('ARTIKUL')}</th>
									}
									<th className="text-center">{t('BARCODE_FOR_SCALE')}</th>
									<th className="text-center">{t('SALE_PRICE')}</th>
									<th className="text-center table-control">
										<i className="uil-setting table-control-icon" onClick={() => setModalTableControl(true)}></i>
										{t('ACTION')}
									</th>
								</tr>
							</thead>
							<tbody>
								{data.length > 0 ? data.map((item, index) => (
									<tr key={index}>
										{(!reduxSettings.tableScale || reduxSettings.tableScale?.pos) &&
											<td>{item.rowNum}. {item.posName}</td>
										}
										<td>{item.productName}</td>
										<td className="text-center">{item.productUomName}</td>
										<td className="text-center">{item.productBarcode}</td>
										{(!reduxSettings.tableScale || reduxSettings.tableScale?.artikul) &&
											<td className="text-center">{item.productArtikul}</td>
										}
										<td className="text-center">{item.barcodeScales}</td>
										<td className="text-center">{formatMoney(item.salePrice)}</td>
										<td className="d-flex justify-content-center">
											{canDelete &&
												<div className="table-action-button table-action-danger-button" onClick={() => deleteItem(item)}>
													<i className="uil uil-trash-alt"></i>
												</div>
											}
										</td>
									</tr>
								)): <tr>
										<td colSpan="10" className="text-center">{t('NO_DATA')}</td>
									</tr>
								}

							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			{/* MODAL TABLE CONTROL */}
			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'tableScale'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}
		</>
	)
}

export default Index
