import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import Select, { components } from 'react-select'
import { Dropdown } from 'react-bootstrap'

import useDidMountEffect from 'pages/parts/useDidMountEffect'
import Pagination, { ItemPerPage } from 'pages/parts/Pagination'

import { GET, PGET, POST } from 'helpers/api'
import { checkRole, findFromArrayById, returnSign } from "helpers/helpers"
import { SET_TABLE_FILTER } from 'store/tableFilter/actions';
import { DebounceInput } from "react-debounce-input"
import { toast } from "react-toastify"
import FilterPagination from "pages/parts/FilterPagination"

function Index() {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const reduxSettings = useSelector(state => state.settings)
	const reduxTableFilter = useSelector(state => state.tableFilter)

	const URL = `/services/web/api/product-pageList`

	const canCreate = checkRole('MAIN_PRODUCTS_ADD')
	const canUpdate = checkRole('MAIN_PRODUCTS_EDIT')
	const reduxAccount = useSelector(state => state.account)

	const seasons = [{ id: 0, name: t('EMPTY') }, { id: 1, name: t('SEASONAL') }, { id: 2, name: t('NOT_SEASONAL') }];

	const [categories, setCategories] = useState([])
	const [categoriesInitial, setCategoriesInitial] = useState([])
	const [isOpen, setIsOpen] = useState(false)
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [ItemsPerPage, setItemsPerPage] = useState(20)
	const [pagination, setPagination] = useState({
		url: `${URL}/${reduxAccount.posId}`,
		response: null,
		increment: 0,
	})
	const [allVat, setAllVat] = useState()
	const [filterData, setFilterData] = useState({
		'search': '',
		'categoryId': '',
		'complete': '',
		'page': '',
	})

	async function searchWithFilter(settings = { tableSort: '' }) {

		var filterUrl = "/services/web/api/product-pageList"
		var urlParams = ""
		checkFilter()

		if (filterData?.categoryId)
			urlParams += returnSign(urlParams) + 'categoryId=' + filterData?.categoryId
		if (filterData?.seasonal)
			urlParams += returnSign(urlParams) + 'seasonal=' + filterData?.seasonal
		if (filterData?.complete)
			urlParams += returnSign(urlParams) + 'complete=' + filterData?.complete
		if (filterData?.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData?.search
		if (filterData?.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData?.page
		if (`/services/web/api/product-pageList` !== filterUrl)
			setFilterDataExist(true)

		if (settings.tableSort) {
			var filterDataCopy = { ...filterData }
			if (filterDataCopy.tableSortKey === settings.tableSort) {
				filterDataCopy.tableSortDirection === ',asc' ? filterDataCopy.tableSortDirection = ',desc' : filterDataCopy.tableSortDirection = ',asc'
			} else {
				filterDataCopy.tableSortDirection = ',asc'
			}
			filterDataCopy.tableSortKey = settings.tableSort

			setFilterData(filterDataCopy)
			urlParams += returnSign(urlParams) + 'sort=' + settings.tableSort + filterDataCopy.tableSortDirection
		}

		filterUrl = filterUrl + urlParams

		const response = await PGET(`/services/web/api/product-pageList`, { ...filterData, size: ItemsPerPage }, { loader: true })
		if (response) {
			setPagination({ ...pagination, 'url': `/services/web/api/product-pageList/`, 'response': response })

			setPageCount(Math.ceil(response.headers['x-total-count'] / ItemsPerPage))
			setData(response.data)
		}

		setDropdownIsOpen(false)
	}

	function checkFilter() {
		if (
			!filterData.categoryId &&
			!filterData.complete &&
			!filterData.page &&
			!filterData.search
		) {
			setFilterDataExist(false)
		}
	}

	function resetFilter() {
		setFilterData({
			categoryId: null,
			complete: null,
			search: '',
		})
		setFilterDataExist(false)
		searchWithFilter()
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(data) {
		const response = await PGET(URL, { ...filterData, page: data.selected, size: reduxSettings.size }, { loader: true })
		setFilterData({ ...filterData, page: data.selected })
		setPageCount(Math.ceil(response.headers['x-total-count'] / reduxSettings.size))
		setData(response.data);
	}

	async function getCategories() {
		const response = await GET('/services/web/api/product-category-helper')
		var arr = []
		for (let i = 0; i < response.length; i++) {
			response[i]['label'] = ' ' + response[i]['name']
			response[i].child = false;
			if (response[i].parentId !== 0) {
				const index = response.findIndex(x => x.id === response[i].parentId)
				response[i].child = true;
				if (response[index].options && response[index].options.length > 0) {
					response[index].options.push(response[i])
				} else {
					response[index].options = [response[i]]
				}
			} else {
				arr.push(response[i])
			}
		}
		setCategories(arr)
		setCategoriesInitial(response)
	}

	async function selectCategory(option) {
		setIsOpen(!isOpen)
		setFilterData({ ...filterData, 'categoryId': option?.id ? option.id : '' })
	}

	const Option = props => {
		return (
			<div style={{
				padding: props.data.child ? '0 0 0 20px' : '0', color: '#999',
				fontWeight: props.data.parentId === 0 ? 'bold' : 'normal', fontSize: '.8rem'
			}}>
				<components.Option {...props} />
			</div>
		);
	};

	const GroupHead = props => {
		return (
			<div style={{
				padding: '0',
				color: filterData.categoryId === props.data.id ? '#fff' : '#999',
				fontWeight: 'bold',
				backgroundColor: filterData.categoryId === props.data.id ? '#2684FF' : '#fff',
				fontSize: '.8rem'
			}}
				onClick={() => selectCategory(props.data)}>
				<components.Option {...props} />
			</div>
		);
	};

	useEffect(() => {
		getCategories()

		if (reduxTableFilter.page === 'products') {
			// setFilterData(reduxTableFilter.filters)
			setFilterData({ ...filterData, ...reduxTableFilter.filters, page:0, random: filterData.random + 1 })
		} else {
			searchWithFilter()
		}
	}, [ItemsPerPage]) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		dispatch(SET_TABLE_FILTER({
			'page': 'products',
			'filters': {
				'search': filterData.search ? filterData.search : '',
				'categoryId': filterData.categoryId ? filterData.categoryId : '',
				'complete': filterData.complete ? filterData.complete : '',
				'page': filterData.page ? filterData.page : 0,
			}
		}))
	}, [filterData, ItemsPerPage]) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		searchWithFilter()
	}, [filterData.page, filterData.search, ItemsPerPage]) // eslint-disable-line react-hooks/exhaustive-deps


	function showVatInput(index) {
		console.log("AAAA")
		var dataCopy = [...data]
		for (let i = 0; i < dataCopy.length; i++) {
			dataCopy[index].showInput = true
			dataCopy[index].vat = true
			if (!dataCopy[i].showInput) {
				dataCopy[i].showInput = false
			}
		}
		setData(dataCopy)
	}

	const setVat = async (index) => {
		let dataCopy = [...data];
		const product = dataCopy[index];

		try {
			const response = await POST('/services/web/api/update-vat-single', { id: product.id, vat: product.vat });
			console.log(`VAT updated for product id ${product.id}:`, response);
			// Optionally hide the input after a successful update
			product.showInput = false;
			setData(dataCopy);
		} catch (error) {
			console.error(`Error updating VAT for product id ${product.id}:`, error);
		}
	};

	const setVatAll = async () => {
		// Make a shallow copy of the current data array
		let dataCopy = [...data];

		// Create an array of objects each containing the id and vat value
		const vatUpdates = dataCopy.filter(item => typeof item.vat !== 'undefined' && item.vat !== "").map(item => ({
			id: item.id,
			vat: item.vat
		}));

		try {
			const response = await POST('/services/web/api/update-vat', { updates: vatUpdates });
			console.log('VATs updated successfully:', response);

			setData(prevData =>
				prevData.map(item => ({
					...item,
					showInput: false
				}))
			);
		} catch (error) {
			console.error('Error updating VATs:', error);
		}


	};

	function removeVatInput(index) {
		var dataCopy = [...data]
		dataCopy[index].showInput = false
		dataCopy[index].vat = ''
		setData(dataCopy)
	}

	const SetUpALL = () => {
		let dataCopy = [...data]
		dataCopy.map(item => {
			item.vat = allVat
			item.showInput = true
		})
		setData(dataCopy)
	}


	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('PRODUCTS')}
				</h4>
				<div className="d-flex">
					{canCreate &&
						<Link className="btn-rounded btn btn-primary" to="/products-create">
							<i className="uil uil-plus me-2"></i>
							{t('CREATE')}
						</Link>
					}
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3 justify-content-between">
						<div className="d-flex">
							<div className="me-2">
								<div className="position-relative me-2">
									<i className="uil-search input-search-icon"></i>
									<DebounceInput
										type="text"
										className="form-control"
										placeholder={t('SEARCH') + '...'}
										minLength={3}
										debounceTimeout={1000}
										value={filterData.search}
										onChange={(e) => {
											if (e.target.value.length > 0) {
												setFilterData({ ...filterData, search: e.target.value })
											} else {
												setFilterData({ ...filterData, search: '' })
											}
										}}
										onKeyUp={(e) => {
											if (e.keyCode === 13) {
												if (e.target.value.length > 0) {
													setFilterData({ ...filterData, search: e.target.value })
												} else {
													setFilterData({ ...filterData, search: '' })
												}

											}
										}}
									/>
								</div>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('CATEGORY')}</label>
												<Select
													isClearable
													components={{ GroupHeading: GroupHead, Option }}
													classNamePrefix="react-select"
													options={categories}
													value={categoriesInitial.find(option => option.id === filterData.categoryId || '')}
													onChange={(option) => selectCategory(option)}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.label}
													getOptionValue={(option) => option.id}
													onMenuOpen={() => setIsOpen(true)}
													onBlur={() => setIsOpen(false)}
													onMenuClose={() => setIsOpen(false)}
													menuIsOpen={isOpen}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('SEASONALITY')}</label>
												<Select
													isClearable
													classNamePrefix="react-select"
													options={seasons}
													value={seasons.find(option => option.id === filterData.seasonal || '')}
													onChange={(option) => setFilterData({ ...filterData, 'seasonal': option?.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('COMPLETED')}%</label>
												<input type="number" className="form-control"
													value={filterData.complete}
													onChange={(e) => setFilterData({ ...filterData, 'complete': e.target.value })} />
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button type="button" className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button type="button" className="btn btn-primary" onClick={() => searchWithFilter()}>{t('APPLY')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
						<div className="d-flex">
							<div className="form-group position-relative mx-2 mb-0">
								<input type="number" className="form-control" placeholder={t('VAT')} value={allVat} onChange={(e) => setAllVat(e.target.value)} />
							</div>
							<button type="button" className="btn btn-primary mx-2" onClick={() => SetUpALL()}>{t('APPLY_ALL')}</button>
							<button type="button" className="btn btn-primary" onClick={() => setVatAll()}>{t('SAVE')}</button>
						</div>
					</div>
					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.categoryId &&
									<span className="me-2">
										<b>{t('CATEGORY')}: </b> {findFromArrayById(categoriesInitial, filterData.categoryId)}
									</span>
								}
								{filterData.seasonal &&
									<span className="me-2">
										<b>{t('SEASONALITY')}: </b> {findFromArrayById(seasons, filterData.seasonal)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<ItemPerPage
						itemsPerPage={ItemsPerPage}
						setItemsPerPage={(e) => {
							setItemsPerPage(e); // Update ItemsPerPage
							setFilterData({ ...filterData, page: 0, random: filterData.random + 1 }); // Reset to the first page
							searchWithFilter(); // Fetch data with the new ItemsPerPage and page 0
						}}
					/>

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('IMAGE2')}</th>
									<th className="th-sortable cursor" onClick={() => searchWithFilter({ tableSort: 'name' })}>
										{t('NAME_OF_PRODUCT')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'name') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'name') && 'sort-active')}></i>
										</div>
									</th>
									<th className="text-center">{t('BARCODE')}</th>
									<th className="text-center">{t('CATEGORY')}</th>
									<th className="text-center">{t('VAT')}</th>
									<th className="text-center">{t('VAT')}/{t('ACTION')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className="tr-middle" key={index}>
										<td>
											{item.imageUrl ?
												<img src={process.env.REACT_APP_PROD_SERVER_URL + item.imageUrl} alt="react" width="50" height="50" />
												:
												<div className="table-no-image">
													<i className="uil uil-camera position-relative fz-20" style={{ 'top': '20%' }}></i>
												</div>
											}
										</td>
										<td>{item.name}</td>
										<td className="text-center">{item.barcode}</td>
										<td className="text-center">{item.categoryName}</td>
										<td>{item.vat}</td>
										<td>
											{!item.showInput &&
												<div className="table-action-button table-action-primary-button" onClick={() => showVatInput(index)}>
													<i className="uil-edit-alt"></i>
												</div>
											}

											{item.showInput &&
												<div className="d-flex align-items-center justify-content-between">
													<div className="form-group col-6">
														<input type="number" className="form-control" value={item.vat} placeholder={t('VAT')}
															onChange={(e) => {
																const newVat = e.target.value;
																const updatedData = [...data];
																updatedData[index].vat = newVat;
																setData(updatedData);
															}}
														/>
													</div>
													<div className="d-flex">
														<div className="table-action-button table-action-primary-button me-2" onClick={() => setVat(index)}>
															<i className="uil-check"></i>
														</div>
														<div className="table-action-button table-action-danger-button" onClick={() => removeVatInput(index)}>
															<i className="uil-times"></i>
														</div>
													</div>
												</div>
											}
										</td>
										<td>
											<div className="d-flex justify-content-center">
												{canUpdate &&
													<Link to={'/products-update/' + item.id}>
														<div className="table-action-button table-action-primary-button">
															<i className="uil-edit-alt"></i>
														</div>
													</Link>
												}
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>
					<Pagination pageCount={pageCount} paginate={paginate} reduxPage={filterData.page}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index
