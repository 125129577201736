import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Dropdown } from 'react-bootstrap'
import Select from 'react-select'
import FilterSearch from 'pages/parts/FilterSearch'
import Pagination from 'pages/parts/Pagination'
import TableControl from 'pages/parts/TableControl'

import { DOWNLOAD_FILE, GET, PGET } from 'helpers/api'
import { formatMoney, formatDateWithTime, checkRole, findFromArrayById } from 'helpers/helpers'
import useDidMountEffect from "pages/parts/useDidMountEffect"

function Index() {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const reduxSettings = useSelector(state => state.settings)
	const reduxFilterData = useSelector(state => state.filterData)

	const url = `/services/web/api/transfer-pageList`

	const canCreate = checkRole('POS_TRANSFER_ADD')
	const canUpdate = checkRole('POS_TRANSFER_EDIT')

	const [pageCount, setPageCount] = useState(0)

	const [modalTableControl, setModalTableControl] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: null,
		search: '',
		page: 0,
		random: 0,
	})
	const [poses, setPoses] = useState([])
	const [data, setData] = useState([])

	async function exportToExcel(item) {
		await DOWNLOAD_FILE(`/services/web/api/transfer-excel/${item.id}`, t('DISPLACEMENT'))
	}

	function resetFilter() {
		setFilterData({
			posId: null,
			search: '',
			random: filterData.random + 1,
		})
	}

	function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		} else {
			setFilterDataExist(true)
		}
	}

	async function getData() {
		checkFilter()
		const response = await PGET(url, { ...filterData }, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / reduxSettings.size))
			setData(response.data)
			setDropdownIsOpen(false)
		}
	}

	async function paginate(data) {
		const response = await PGET(url, { ...filterData, page: data.selected, size: reduxSettings.size }, { loader: true })
		setFilterData({ ...filterData, page: data.selected })
		setPageCount(Math.ceil(response.headers['x-total-count'] / reduxSettings.size))
		setData(response.data);
	}

	async function getPoses() {
		const response = await GET("/services/web/api/pos-helper")
		if (response) {
			response.unshift({ 'id': null, 'name': t('ALL') })
			setPoses(response)
		}
	}

	useEffect(() => {
		if (reduxFilterData?.transfer) {
			setFilterData({ ...reduxFilterData?.transfer, random: filterData.random + 1 })
		} else {
			getData(true)
		}
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		getData()
	}, [filterData.random]) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		dispatch({ type: 'SET_FILTER_DATA', payload: { key: 'transfer', value: { ...filterData } } })
	}, [filterData]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('DISPLACEMENT')}
				</h4>
				<div className="d-flex">
					{canCreate &&
						<Link className="btn-rounded btn btn-primary" to="/transfer/create">
							<i className="uil uil-plus me-2"></i>
							{t('CREATE')}
						</Link>
					}
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div>
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}
								reduxFilterSearch={true}
							/>
						</div>
						<div>
							<Dropdown onToggle={() => setDropdownIsOpen(!dropdownIsOpen)} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('POS')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.posId) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button type="button" className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button type="button" className="btn btn-primary" onClick={() => setFilterData({ ...filterData, page: 0, random: filterData.random + 1 })}>{t('APPLY')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block mb-3">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('SENDER')}</th>
									<th>{t('RECIPIENT')}</th>
									<th className="text-center">{t('AMOUNT')}</th>
									<th className="text-center">{t('SENDED')}</th>
									<th className="text-center">{t('SEND_DATE')}</th>
									<th className="text-center">{t('RECEIVED')}</th>
									<th className="text-center">{t('RECEIVED_DATE')}</th>
									<th className="text-center">{t('STATUS')}</th>
									<th className="text-center">{t('DATE_OF_CREATION')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.length > 0 ? data.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum}. {item.fromPosName}</td>
										<td>{item.toPosName}</td>
										<td className="text-center">{formatMoney(item.totalAmount)}</td>
										<td className="text-center">
											{!item.send ?
												<span className="bg-secondary bg-border text-white">{t('NO')}</span>
												:
												<span className="bg-success bg-border text-white">{t('YES')}</span>
											}
										</td>
										<td className="text-center">{formatDateWithTime(item.sendDate)}</td>
										<td className="text-center">
											{!item.received ?
												<span className="bg-secondary bg-border text-white">{t('NO')}</span>
												:
												<span className="bg-success bg-border text-white">{t('YES')}</span>
											}
										</td>
										<td className="text-center">{formatDateWithTime(item.receivedDate)}</td>
										<td className="text-center">
											{(item.status === 0 && !item.send) &&
												<span className="bg-secondary bg-border text-white">{t('DRAFT')}</span>
											}
											{(item.status === 0 && item.send) &&
												<span className="bg-secondary bg-border text-white">{t('ON_THE_WAY')}</span>
											}
											{item.status === 1 &&
												<span className="bg-warning bg-border text-white text-nowrap">{t('PARTIALLY_RECIEVED')}</span>
											}
											{item.status === 2 &&
												<span className="bg-success bg-border text-white">{t('FULLY_RECIEVED')}</span>
											}
											{item.status === 3 &&
												<span className="bg-success bg-border text-white">{t('CLOSED')}</span>
											}
										</td>
										<td className="text-center">{formatDateWithTime(item.createdDate)}</td>
										<td className="d-flex justify-content-center">
											{item.send ?
												<Link to={'/transfer/preview/' + item.id} className="me-2">
													<div className="table-action-button table-action-primary-button">
														<i className="uil-eye"></i>
													</div>
												</Link>
												:
												canUpdate &&
												<Link to={'/transfer/update/' + item.id} className="me-2">
													<div className="table-action-button table-action-primary-button">
														<i className="uil-edit-alt"></i>
													</div>
												</Link>
											}
											<div className="table-action-button table-action-success-button" onClick={() => exportToExcel(item)}>
												<i className="uil-file-alt"></i>
											</div>
										</td>
									</tr>
								)): <tr>
										<td colSpan="10" className="text-center">{t('NO_DATA')}</td>
									</tr>
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate} reduxPage={filterData.page}></Pagination>
				</div>
			</div>

			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'tableNomenclature'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}
		</>
	)
}

export default Index
