import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import { useForm, Controller } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from './validation'

import { PGET, GET, POST } from 'helpers/api'
import { checkRole, formatDateWithTime, formatMoney } from 'helpers/helpers'

function Index() {
	const URL = '/services/web/api/currency-list'
	const { t } = useTranslation()

	const { register, handleSubmit, formState, control, setValue, getValues, reset } = useForm({
		mode: "onChange",
		resolver: yupResolver(validationSchema),
		defaultValues: {}
	});
	const { errors } = formState

	const settings = useSelector(state => state.settings)
	const reduxAccount = useSelector(state => state.account)

	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]

	const canCreate = checkRole('MAIN_CURRENCY_ADD')

	const [pageCount, setPageCount] = useState(0)
	const [poses, setPoses] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [modalCurrencyHistory, setModalCurrencyHistory] = useState(false)
	const [dataInner, setDataInner] = useState([])
	const [data, setData] = useState([])

	async function createUpdate(validatedData) {
		var sendData = { ...validatedData }

		const response = await POST('/services/web/api/currency', sendData, { loader: true })

		if (response) {
			getData()
			setShowModal(false)
			reset()
		}
	}

	async function openModal(item) {
		const response = await PGET(
			'/services/web/api/currency-id-pageList/' + item.posId + '/' + item.fromId + '/' + item.toId,
			{},
			{ loader: true }
		)
		if (response) {
			setModalCurrencyHistory(true)
			setDataInner(response.data)
		}
	}

	function closeModal() {
		setShowModal(false)
		reset()
	}

	function selectCurrency(option) {
		setValue('fromId', option.id)
		setValue('toId', currencies.filter(e => e.id !== option.id)[0]['id'])
	}

	async function getData() {
		const response = await PGET(`/services/web/api/currency-list/${reduxAccount.posId}`, {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
		}
	}

	async function paginate(data) {
		const response = await PGET(`/services/web/api/currency-list/${reduxAccount.posId}`, { page: data.selected, size: settings.size }, { loader: true })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('CURRENCY')}
				</h4>
				<div className="d-flex">
					{canCreate &&
						<button className="btn-rounded btn btn-primary" onClick={() => setShowModal(true)}>
							<i className="uil uil-plus me-2"></i>
							{t('CREATE')}
						</button>
					}
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('POS')}</th>
									<th className="text-center">{t('SOURCE_CURRENCY')}</th>
									<th className="text-center">{t('CONVERTIBLE_CURRENCY')}</th>
									<th className="text-center">{t('CURRENCY_RATE')}</th>
									<th className="text-center">{t('WHOLESALE_PRICE')}</th>
									<th className="text-center">{t('SALE_PRICE')}</th>
									<th className="text-center">{t('BANK_PRICE')}</th>
									<th className="text-center">{t('DATE')}</th>
									<th className="text-center">{t('HISTORY')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.posName}</td>
										<td className="text-center">{item.fromName}</td>
										<td className="text-center">{item.toName}</td>
										<td className="text-center">{formatMoney(item.currentRate)}</td>
										<td className="text-center">{formatMoney(item.wholesalePriceRate)}</td>
										<td className="text-center">{formatMoney(item.salePriceRate)}</td>
										<td className="text-center">{formatMoney(item.bankPriceRate)}</td>
										<td className="text-center">{formatDateWithTime(item.createdDate)}</td>
										<td className="d-flex justify-content-center">
											<div className="table-action-button table-action-primary-button" onClick={() => openModal(item)}>
												<i className="uil uil-list-ui-alt"></i>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{getValues('id') ?
							<>{t('EDIT')}</>
							:
							<>{t('CREATE')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(createUpdate)} autoComplete="off">
						<div className="form-group">
							<label>{t('POS')}<span className="required-mark">*</span></label>
							<Controller
								name="posId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										options={poses}
										value={poses.find(option => option.id === getValues('posId')) || ''}
										onChange={(e) => setValue('posId', e.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>}
							/>
						</div>
						<div className="form-group">
							<label>{t('SOURCE_CURRENCY')}<span className="required-mark">*</span></label>
							<Controller
								name="fromId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										options={currencies}
										value={currencies.find(option => option.id === getValues('fromId')) || ''}
										onChange={(option) => selectCurrency(option)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>}
							/>
						</div>
						<div className="form-group">
							<label>{t('CONVERTIBLE_CURRENCY')}<span className="required-mark">*</span></label>
							<Controller
								name="toId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										isDisabled={true}
										options={currencies}
										value={currencies.find(option => option.id === getValues('toId')) || ''}
										onChange={(e) => setValue('toId', e.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>}
							/>
						</div>
						<div className="form-group">
							<label>{t('CONVERTIBLE_CURRENCY')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="currentRate" {...register('currentRate')} />
							<span className="text-danger text-sm">{errors.currentRate?.message}</span>
						</div>
						<div className="form-group">
							<label>{t('WHOLESALE_PRICE')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="wholesalePriceRate" {...register('wholesalePriceRate')} />
							<span className="text-danger text-sm">{errors.wholesalePriceRate?.message}</span>
						</div>
						<div className="form-group">
							<label>{t('SALE_PRICE')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="salePriceRate" {...register('salePriceRate')} />
							<span className="text-danger text-sm">{errors.salePriceRate?.message}</span>
						</div>
						<div className="form-group">
							<label>{t('BANK_PRICE')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="bankPriceRate" {...register('bankPriceRate')} />
							<span className="text-danger text-sm">{errors.bankPriceRate?.message}</span>
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			{/* MODAL */}
			<Modal show={modalCurrencyHistory} animation={false} centered backdrop="static" size="lg" onHide={() => setModalCurrencyHistory(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('HISTORY')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table-striped table">
							<thead>
								<tr>
									<th>{t('POS')}</th>
									<th className="text-center">{t('SOURCE_CURRENCY')}</th>
									<th className="text-center">{t('CONVERTIBLE_CURRENCY')}</th>
									<th className="text-center">{t('CURRENCY_RATE')}</th>
									<th className="text-center">{t('DATE')}</th>
								</tr>
							</thead>
							<tbody>
								{dataInner.map((item, index) => (
									<tr key={index}>
										<td>{item.posName}</td>
										<td className="text-center">{item.fromName}</td>
										<td className="text-center">{item.toName}</td>
										<td className="text-center">{item.currentRate}</td>
										<td className="text-center">{formatDateWithTime(item.createdDate)}</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
