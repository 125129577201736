import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import XLSX from 'xlsx';

import { Dropdown } from 'react-bootstrap'
import Select from 'react-select'
import FilterSearch from 'pages/parts/FilterSearch'
import Pagination from 'pages/parts/Pagination'

import { GET, PGET } from 'helpers/api'
import { formatMoney, formatDate, checkRole, findFromArrayById } from 'helpers/helpers'

function Index() {

	const { t } = useTranslation()

	const reduxSettings = useSelector(state => state.settings)
	const reduxAccount = useSelector(state => state.account)

	const URL = `/services/web/api/documents-out-pageList/`

	const canCreate = checkRole('POS_DOCUMENT_OUT_ADD')

	const [pageCount, setPageCount] = useState(0)

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: reduxAccount.posId,
		search: '',
	})
	const [poses, setPoses] = useState([])
	const [data, setData] = useState([])

	async function exportToExcel(item) {
		const response = await GET('/services/web/api/documents-out/' + item.id)

		var excelHeaders = []
		excelHeaders.push([t('DOCUMENT_FROM'), formatDate(response?.createdDate)])
		excelHeaders.push([t('POS'), response['posName'], t('RETURN') + '№', response?.outNumber])
		excelHeaders.push([t('SUPPLIER'), response['organizationName'], t('NOTE'), response?.note])
		excelHeaders.push([t('CURRENCY'), formatMoney(response['currencyName'])])
		excelHeaders.push([''])
		excelHeaders.push([
			t('NAME_OF_PRODUCT'), t('BARCODE'), t('SERIAL'), t('EXPIRATION_DATE'), t('RETURN_QUANTITY'), t('ADMISSION_PRICE'), t('DIVERGENCE'), t('TOTAL_AMOUNT')
		])

		for (let i = 0; i < response.productList.length; i++) {
			var excelItems = []
			excelItems.push(response.productList[i]['productName'])
			excelItems.push(response.productList[i]['barcode'])
			excelItems.push(response.productList[i]['serial'])
			excelItems.push(response.productList[i]['expDate'])
			excelItems.push(response.productList[i]['quantity'] + response.productList[i].uomName)
			excelItems.push(formatMoney(response.productList[i]['totalAmount']))
			excelHeaders.push(excelItems)
		}
		excelHeaders.push([''])
		excelHeaders.push([t('TOTAL'), formatMoney(response['totalAmount'])])

		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('RETURN') + ".xlsx");
	}

	async function getData() {
		checkFilter()
		const response = await PGET(URL + filterData.posId, filterData)
		if (response && response.data) {
			setPageCount(Math.ceil((response?.headers['x-total-count']) / reduxSettings.size))
			setData(response.data)
			setDropdownIsOpen(false)
		}
	}

	function resetFilter() {
		setFilterData({
			...filterData,
			search: '',
		})
		getData()
	}

	function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		} else {
			setFilterDataExist(true)
		}
	}

	async function paginate(data) {
		const response = await PGET(URL + filterData.posId, { ...filterData, page: data.selected, size: reduxSettings.size })
		setData(response.data);
	}

	async function getPoses() {
		const response = await GET("/services/web/api/pos-helper", {}, { loader: true })
		if (response) {
			setPoses(response)
		}

	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getData(true)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('RETURN_TO_SIPPLIER')}
				</h4>
				<div className="d-flex">
					{canCreate &&
						<Link className="btn-rounded btn btn-primary" to="/documents-out/create">
							<i className="uil uil-plus me-2"></i>
							{t('CREATE')}
						</Link>
					}
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div>
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={() => setDropdownIsOpen(!dropdownIsOpen)} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('POS')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.posId) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button type="button" className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button type="button" className="btn btn-primary" onClick={() => getData(true)}>{t('APPLY')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block mb-3">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('POS')}</th>
									<th>{t('SUPPLIER')}</th>
									<th className="text-center">№ {t('RETURN')}</th>
									<th className="text-center">{t('AMOUNT')}</th>
									<th className="text-center">{t('DATE_OF_RETURN')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.length > 0 ? data.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum}. {item.posName}</td>
										<td>{item.organizationName}</td>
										<td className="text-center">{item.outNumber}</td>
										<td className="text-center">{formatMoney(item.totalAmount)}</td>
										<td className="text-center">{formatDate(item.createdDate)}</td>
										<td className="d-flex justify-content-center">
											<Link to={`/documents-out/preview/${item.id}`}>
												<div className="table-action-button table-action-primary-button me-2">
													<i className="uil-eye"></i>
												</div>
											</Link>
											<div className="table-action-button table-action-success-button" onClick={() => exportToExcel(item)}>
												<i className="uil-file-alt"></i>
											</div>
										</td>
									</tr>
								)): <tr>
										<td colSpan="10" className="text-center">{t('NO_DATA')}</td>
									</tr>
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index
