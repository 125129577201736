import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { GET, POST } from 'helpers/api'
import { formatDateBackend, formatDateWithTime, formatMoney } from 'helpers/helpers'
import loader from "store/loader/reducer"
import { Dropdown } from "react-bootstrap"
import DatePicker from "react-datepicker"
import TourExample from "Tour"
import CreateUpdateModal from "../documentsIn/CreateUpdateModal"

function Index() {
	const URL = "services/web/api/sd/getSdOrders"
	const { t } = useTranslation()

	const reduxSettings = useSelector(state => state.settings)
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])
	const [status, setStatus] = useState([{
		id: 1,
		name: t('STATUS_NEW'),
		color: "#34C38F"
	}, {
		id: 2,
		name: t('STATUS_LOADED'),
		color: "#0000FF99"
	}, {
		id: 3,
		name: t('STATUS_DELIVERED'),
		color: "#F1B44C"
	}, {
		id: 4,
		name: t('STATUS_RETURNED'),
		color: "gray"
	}, {
		id: 5,
		name: t('STATUS_CANCEL'),
		color: "#FF000099"
	}])

	const reduxAccount = useSelector(state => state.account)
	const date = new Date()

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterData, setFilterData] = useState({
		// posId: reduxAccount.posId,
		// search: '',
		startDate: formatDateBackend(new Date(date.getFullYear(), date.getMonth() - 1, date.getDate())),
		endDate: formatDateBackend(new Date()),
	})
	const [filterDataExist, setFilterDataExist] = useState(false)




	function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		} else {
			setFilterDataExist(true)
		}
	}



	async function getData() {
		checkFilter(true)
		const response = await GET(URL, filterData, { loader: true })
		if (response) {
			const response2 = await GET('/services/web/api/dealer-helper', {}, { loader: true })
			if (response2) {
				response2.map(dealer => {
					response?.result.map(item => {
						if (item.dealerId == dealer.id) {
							item.dealerName = dealer.name;
						}
					})
				})
			}
			const temporaryPoses = await GET('/services/web/api/pos-helper', {}, { loader: true });
			if (temporaryPoses) {
				temporaryPoses.map(pos => {
					response?.result.map(item => {
						if (item.posId == pos.id) {
							item.posName = pos.name;
						}
					})
				})
			}
			setData(response?.result.reverse() || [])
		}

	}
	const [show, setshow] = useState(false)
	const [ModalData, setModalData] = useState()

	const saveProduct = async (idx) => {
		// console.log(data[idx]);
		setModalData(data[idx])
		setshow(true)
	}



	useEffect(() => {
		getData()
	}, [])

	return (
		<>
			{show && <CreateUpdateModal product={ModalData}
				callbakc_func={(status) => {
					setshow(status)
					getData()
				}} />
			}
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('ORDERS_SD')}
				</h4>
				<div className="d-flex">
					<Link className="btn-rounded btn btn-primary" to="/sd-purchase/create">
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</Link>
				</div>
			</div>
			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						{/* <div>
						<FilterSearch
							filterData={filterData}
							setFilterData={setFilterData}>
						</FilterSearch>
					</div> */}
						<div>
							<Dropdown onToggle={() => setDropdownIsOpen(!dropdownIsOpen)} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('PERIOD')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.startDate ? new Date(filterData.startDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'startDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.endDate ? new Date(filterData.endDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'endDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button type="button" className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button type="button" className="btn btn-primary" onClick={() => getData(true)}>{t('APPLY')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('ID')}</th>
									<th>{t('POS')}</th>
									<th>{t('DEALER')}</th>
									<th className="text-center">{t('QUANTITY')}</th>
									<th className="text-center">{t('AMOUNT')}</th>
									<th className="text-center">{t('DATE_OF_RECEIPT')}</th>
									<th className="text-center">{t('STATUS')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.length > 0 ? data.map((item, index) => (
									<tr key={index}>
										<td>{item.order_id}</td>
										<td>{item.posName}</td>
										<td>{item.dealerName}</td>
										<td className="text-center">{item.count}</td>
										<td className="text-center">{formatMoney(item.summa)}</td>
										<td className="text-center">{formatDateWithTime(item.date)}</td>
										<td className="text-center " style={{ width: "220px" }}>
											<div className="d-flex justify-content-between">
												{
													status.map((onestatus, instatus) => {
														return onestatus.id == item.status ? <>
														
															<>
																<div style={{ display: `${onestatus.id != item.status ? 'none' : 'flex'}`, backgroundColor: `${onestatus.color}`, borderRadius: "10px", color: "white", width: "110px", height: "30px" }} className="align-items-center justify-content-center">
																	{onestatus.id == item.status && onestatus.name}
																</div>
															</>
														</> : ""
													})
												}
												{!item.confirmed && item.status == 3 && (
													item.details.some(detail => detail.uom_id === null) ? (
														<div className="w-50 d-flex justify-content-start">
															<div className="ms-2 d-inline-flex justify-content-center align-items-center rounded-circle bg-danger text-white"
															style={{ width: "30px", height: "30px", fontSize: "14px", cursor: "pointer" }}
															title={t('EXTERNAL_ORDER_NOTIFICATION')}
															>i</div>
														</div>
													) : (
														<div
															onClick={() => saveProduct(index)}
															className="btn-rounded px-2 py-1 btn-primary cursor"
															style={{ height: "30px" }}
														>
															{t('GOOD_RECEPTION')}
														</div>
													)
												)}

											</div>

										</td>
										<td className="d-flex justify-content-center">
											<Link to={`/sd-purchase/view/${item.order_id}`} state={{ details: item }} className="me-2">
												<div className="table-action-button table-action-primary-button">
													<i className="uil-eye"></i>
												</div>
											</Link>
										</td>


									</tr>
								)) : <tr>
									<td colSpan="10" className="text-center">{t('NO_DATA')}</td>
								</tr>
								}
							</tbody>
						</table>
					</div>

					{/* <Pagination pageCount={pageCount} paginate={paginate}></Pagination> */}
				</div>
			</div>
		</>
	)
}

export default Index
