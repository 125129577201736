import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import Select from 'react-select'


function Pagination({ pageCount, paginate, filters, reduxPage }) {
	const [currentPage, setCurrentPage] = useState(0)

	async function getCurrentPage(pageNumber, bool = true) {
		if (bool) {
			setCurrentPage(pageNumber.selected)
			paginate(pageNumber)
		}
	}

	function forceToPage(page) {
		setCurrentPage(page)
		getCurrentPage({ 'selected': page }, false)
	}

	useEffect(() => {
		if (reduxPage >= 0) {
			setCurrentPage(reduxPage)
		}
	}, [reduxPage]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (filters?.page >= 0) {
			forceToPage(filters?.page)
		}
	}, [filters?.posId]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="d-flex">
			{pageCount > 1 && (
				<ReactPaginate
					forcePage={currentPage}
					breakLabel={'...'}
					breakClassName={'break-me'}
					pageCount={pageCount}
					marginPagesDisplayed={3}
					pageRangeDisplayed={3}
					onPageChange={getCurrentPage}
					containerClassName={'pagination'}
					activeClassName={'active'}
					pageClassName={'page-item'}
				/>
			)}
		</div>	
	)
}

export default Pagination

export const ItemPerPage = ({ itemsPerPage = 20, setItemsPerPage }) => {
	// Sahifada nechta element ko‘rinishi uchun variantlar
	const itemsPerPageOptions = [
		// { value: 10, label: '10' },
		{ value: 20, label: '20' },
		{ value: 50, label: '50' },
		{ value: 100, label: '100' },
		{ value: 200, label: '200' },
		{ value: 500, label: '500' },
		{ value: 1000, label: '1000' },
	]

	return (
		<div style={{width: '100px'}}>
			<Select
			options={itemsPerPageOptions}
			value={itemsPerPageOptions.find(option => option.value === itemsPerPage)}
			onChange={(e) => setItemsPerPage(e.value)}
			isSearchable={false}
			placeholder="Items per page"
		/>
		</div>
	)
}

