import React from 'react';

const InfoBlock = ({ title, message, type = 'info' }) => {
  // Define styles based on type
  const styles = {
    info: {
      backgroundColor: '#d1ecf1',
      borderColor: '#bee5eb',
      color: '#0c5460',
    },
    warning: {
      backgroundColor: '#fff3cd',
      borderColor: '#ffeeba',
      color: '#856404',
    },
    error: {
      backgroundColor: '#f8d7da',
      borderColor: '#f5c6cb',
      color: '#721c24',
    },
  };

  const currentStyle = styles[type] || styles.info;

  return (
    <div style={{
      padding: '16px',
      border: `1px solid ${currentStyle.borderColor}`,
      borderRadius: '4px',
      backgroundColor: currentStyle.backgroundColor,
      color: currentStyle.color,
      marginBottom: '16px',
    }}>
      <h4 style={{ margin: '0 0 8px' }}>{title}</h4>
      <p style={{ margin: 0 }}>{message}</p>
    </div>
  );
};

export default InfoBlock;
