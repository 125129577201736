import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom"
import { useSelector } from 'react-redux'

import { Dropdown } from 'react-bootstrap'
import Select from 'react-select'
import FilterSearch from 'pages/parts/FilterSearch'
import Pagination from 'pages/parts/Pagination'

import { GET, PGET, PUT } from 'helpers/api'
import { checkRole, findFromArrayById, formatDateWithTime } from 'helpers/helpers'


function Index() {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const reduxSettings = useSelector(state => state.settings)
	const reduxAccount = useSelector(state => state.account)

	const URL = `/services/web/api/product-box-pageList/`

	const canCreate = checkRole('POS_PRODUCT_BOX_ADD')
	const canUpdate = checkRole('POS_PRODUCT_BOX_EDIT')

	const [pageCount, setPageCount] = useState(0)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: reduxAccount.posId,
		search: '',
	})
	const [poses, setPoses] = useState([])
	const [data, setData] = useState([])

	async function changeStatus(e, index) {
		var dataCopy = [...data]
		dataCopy[index][e.target.name] = e.target.checked
		setData(dataCopy)
		var sendData = {
			id: dataCopy[index]['id'],
			activated: e.target.checked,
		}
		await PUT(`/services/web/api/product-box-activate`, sendData, { loader: true })
	}

	async function getData() {
		checkFilter()
		const response = await PGET(URL + filterData.posId, filterData)
		if (response && response.data) {
			setPageCount(Math.ceil((response?.headers['x-total-count']) / reduxSettings.size))
			setData(response.data)
			setDropdownIsOpen(false)
		}
	}

	function resetFilter() {
		setFilterData({
			...filterData,
			search: '',
		})
		getData()
	}

	function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		} else {
			setFilterDataExist(true)
		}
	}

	async function paginate(data) {
		const response = await PGET(URL + filterData.posId, { ...filterData, page: data.selected, size: reduxSettings.size })
		setData(response.data);
	}

	async function getPoses() {
		const response = await GET("/services/web/api/pos-helper", {}, { loader: true })
		if (response) {
			setPoses(response)
		}

	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getData(true)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('PACKAGES')}
				</h4>
				<div className="d-flex">
					{canCreate &&
						<button className="btn-rounded btn btn-primary" onClick={() => navigate('/package/create')}>
							<i className="uil uil-plus me-2"></i>
							{t('CREATE')}
						</button>
					}
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div>
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={() => setDropdownIsOpen(!dropdownIsOpen)} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('POS')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.posId) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button type="button" className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button type="button" className="btn btn-primary" onClick={() => getData(true)}>{t('APPLY')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block mb-3">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('POS')}</th>
									<th>{t('NAME2')}</th>
									<th>{t('BARCODE')}</th>
									<th>{t('ORIGINAL_PRICE')}</th>
									<th>{t('WHOLESALE_PRICE')}</th>
									<th>{t('SALE_PRICE')}</th>
									<th>{t('RESIDUE')}</th>
									<th>{t('SALES')}</th>
									<th className="text-center">{t('ACTIVATED')}</th>
									<th className="text-center">{t('DATE')}</th>
									<th className="text-end">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.length > 0 ? data.map((item, index) => (
									<tr key={index}>
										<td>{item?.posName}</td>
										<td>{item?.productName}</td>
										<td>{item?.productBarcode}</td>
										<td>{item?.price}</td>
										<td>{item?.wholesalePrice}</td>
										<td>{item?.salePrice}</td>
										<td>{item?.quantity}</td>
										<td>{item?.saleQuantity}</td>
										<td>
											<div className="d-flex justify-content-center">
												<div className="form-check form-switch form-switch-lg">
													<input type="checkbox" className="form-check-input"
														name="activated"
														checked={item.activated}
														onChange={(e) => changeStatus(e, index)} />
												</div>
											</div>
										</td>
										<td className="text-center">{formatDateWithTime(item?.createdDate)}</td>
										<td className="d-flex justify-content-end">
											{canUpdate &&
												<div className="table-action-button table-action-primary-button" onClick={() => navigate(`/package/update/${item.id}`)}>
													<i className="uil-edit-alt"></i>
												</div>
											}
										</td>
									</tr>
								)) : <tr>
										<td colSpan="10" className="text-center">{t('NO_DATA')}</td>
									</tr>
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index
