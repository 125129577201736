import React, { useState } from "react"
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { Dropdown } from 'react-bootstrap'

import { GUESTPOST, GET } from 'helpers/api'
import { clearTemporaryStorage } from 'helpers/helpers'

import ru from 'assets/images/flags/ru.png'
import uzLatn from 'assets/images/flags/uz-Latn-UZ.png'
import uzCyrl from 'assets/images/flags/uz-Cyrl-UZ.png'
import '@fortawesome/fontawesome-free/css/all.min.css'
import TourExample from "Tour";

function Login() {
	const { i18n, t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch()

	const [user, setUser] = useState({ "username": "" })

	const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

	async function handleSubmit(e) {
		e.preventDefault();
		var sendData = {
			username: user.username.toLowerCase(),
			password: user.password
		}
		clearTemporaryStorage()
		const response = await GUESTPOST('/auth/login', sendData, { loader: true })
		if (response) {
			if (!response.activated) {
				toast.error(t('ERROR') + ': ' + t('YOUR_ACCOUNT_BLOCKED'))
				return
			}
			localStorage.setItem("username", user.username.toLowerCase());
			localStorage.setItem('access_token', response.access_token)
			localStorage.setItem("tokenTime", JSON.stringify(new Date().getTime()));
			localStorage.setItem("authUser", JSON.stringify(user))

			const account = await GET('/services/uaa/api/account')
			localStorage.setItem("authorities", JSON.stringify(account.authorities));
			dispatch({ type: 'SET_ACCOUNT', payload: { ...account, ...response } })

			var checker = false
			for (let i = 0; i < account.authorities.length; i++) {
				if (account.authorities[i] === "ROLE_OWNER") {
					var role = "ROLE_OWNER"
					checker = true
				}
				if (account.authorities[i] === "ROLE_MERCHANDISER") {
					role = "ROLE_MERCHANDISER"
					checker = true
				}
				if (account.authorities[i] === "ROLE_ORGANIZATION") {
					role = "ROLE_ORGANIZATION"
					checker = true
				}
				if (account.authorities[i] === "ROLE_DIRECTOR") {
					role = "ROLE_DIRECTOR"
					checker = true
				}
				localStorage.setItem("role", role);
			}
			if (checker === true) {
				const temporarySettings = await GET("/services/web/api/user-settings")
				if (temporarySettings.settings) {
					const parsedSettings = JSON.parse(temporarySettings.settings)
					dispatch({ type: 'SET_SETTINGS', payload: parsedSettings })
				}
				switch (role) {
					case "ROLE_MERCHANDISER":
						navigate("/documents-in")
						break;
					case "ROLE_ORGANIZATION":
						navigate("/organization-balance")
						break;
					case "ROLE_OWNER":
						navigate("/documents-in")
						break;
					default:
						navigate("/documents-in")
						break;
				}
			} else {
				toast.error(t('ERROR') + ': ' + t('YOU_DO_NOT_HAVE_ACCESS'))
				clearTemporaryStorage()
			}
		}

	}

	function changeLanguage(language = 'uz-Latn-UZ') {
		i18n.changeLanguage(language);

		if (language === 'ru') {
			localStorage.setItem('lang', 'ru');
		}
		if (language === 'uz-Latn-UZ') {
			localStorage.setItem('lang', 'uz-Latn-UZ');
		}
		if (language === 'uz-Cyrl-UZ') {
			localStorage.setItem('lang', 'uz-Cyrl-UZ');
		}
	};

	function getCurrentLocale() {
		const locale = i18n.language
		if (locale === 'ru') return { flag: ru, lang: 'RUSSIAN' }
		else if (locale === 'uz-Latn-UZ') return { flag: uzLatn, lang: 'UZBEK_LATN' }
		else if (locale === 'uz-Cyrl-UZ') return { flag: uzCyrl, lang: 'UZBEK_CYRL' }
	}

	const LanguageToggle = React.forwardRef(({ children, onClick }, ref) => (
		<span ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>
			{children}
		</span>
	));

	return (
		<>
			<div className="authentication-bg">
				<div className="account-pages h-100 vertical-center">
					<div className="container">
						<div className="row align-items-center justify-content-center">
							<div className="col-md-8 col-lg-6 col-xl-4">

								<div className="auth-card">
									<div className="text-center my-2">
										<h3 className="text-white">{t('LOGGING_IN')}</h3>
									</div>
									<div className="p-2">
										<form className="form-horizontal" autoComplete="off" onSubmit={handleSubmit}>

											<input className="login-input"
												autoFocus
												name="username"
												value={user.username}
												placeholder={t('LOGIN')}
												onChange={(e) => setUser({ ...user, 'username': e.target.value })} />

											<div style={{display: 'flex', width: '250px', margin: '0 auto'}}>
											<input className="login-input"
												name="password"
													type={showPassword ? 'text' : 'password'}
												placeholder={t('PASSWORD')}
												value={user.password}
												onChange={(e) => setUser({ ...user, 'password': e.target.value })} />

												<button
														type="button"
														onClick={togglePasswordVisibility}
														style={{
														border: 'none',
														background: 'none',
														cursor: 'pointer',
														margin: '-5px 0 0 -35px',
														color: 'white',
														width: '35px',
														fontSize: '18px'
													}}
													aria-label="Toggle password visibility">
													<i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
												</button>
											</div>								

											<div className="text-center">
												<button type="submit" className="login-button">
													{t('ENTER')}
												</button>
											</div>

											<div className="mt-4 text-center">
												<Link to="/forgot-password" className="fw-medium text-white">{t('FORGOT_PASSWORD')}</Link>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="position-absolute lang-position">
						<Dropdown className="cursor-pointer d-flex cursor">
							<Dropdown.Toggle as={LanguageToggle}>
								<div className="d-flex">
									<div className="vertical-center">
										<img src={getCurrentLocale().flag} className="me-2" alt="ru" width="24" height="16" />
									</div>
									<span>{t(getCurrentLocale().lang)}</span>
								</div>
							</Dropdown.Toggle>

							<Dropdown.Menu>
								{i18n.language !== 'ru' &&
									<Dropdown.Item onClick={() => changeLanguage("ru")}>
										<div className="d-flex">
											<div className="vertical-center"><img src={ru} className="me-2" alt="ru" width="24" height="16" /></div>
											<span>{t('RUSSIAN')}</span>
										</div>
									</Dropdown.Item>
								}
								{i18n.language !== 'uz-Latn-UZ' &&
									<Dropdown.Item onClick={() => changeLanguage("uz-Latn-UZ")}>
										<div className="d-flex">
											<div className="vertical-center"><img src={uzLatn} className="me-2" alt="uz-Latn-UZ" width="24" height="16" /></div>
											<span>{t('UZBEK_LATN')}</span>
										</div>
									</Dropdown.Item>
								}
								{i18n.language !== 'uz-Cyrl-UZ' &&
									<Dropdown.Item onClick={() => changeLanguage("uz-Cyrl-UZ")}>
										<div className="d-flex">
											<div className="vertical-center"><img src={uzCyrl} className="me-2" alt="uz-Cyrl-UZ" width="24" height="16" /></div>
											<span>{t('UZBEK_CYRL')}</span>
										</div>
									</Dropdown.Item>
								}
							</Dropdown.Menu>
						</Dropdown>
					</div>

					<div className="about-idokon position-absolute">
						{t('VERSION')}: 1 <br />
						{t('CONTACT_CENTRE')}: +998 95 137-03-05 <br />
						{t('MADE_BY_AS')} <br />
						{t('LEGAL_ADDRESS')}: <br />
						{t('MADE_BY_AS2')}
					</div>

					<ul className="circles">
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
					</ul>
				</div>
			</div>
		</>
	)
}

export default Login;
