import React, { useEffect, useState } from "react"
import { useNavigate, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

import { GET, POST } from 'helpers/api'
import { formatMoney } from "helpers/helpers";

function CreateUpdate() {
	const { t } = useTranslation()
	const navigate = useNavigate();

	const reduxSettings = useSelector(state => state.settings)

	const [poses, setPoses] = useState([])
	const [data, setData] = useState({
		posId: 0,
		productList: []
	})

	const exportTableToExcel = (event) => {
		event.preventDefault(); // Prevent default form submission (if any)
	  
		const table = document.getElementById("myTable");
		if (!table) {
		  console.error("Table not found");
		  return;
		}
	  
		const wb = XLSX.utils.table_to_book(table);
		const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
	  
		saveAs(new Blob([wbout], { type: "application/octet-stream" }), "table.xlsx");
	};

	const importExcel = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            if (jsonData.length < 2) {
                console.error("No data found in the Excel file");
                return;
            }

            const columnEValues = jsonData.slice(1).map((row) => row[4] || "");
            setData((prevData) => {
                const updatedProductList = prevData.productList.map((item, index) => {
                    return {
                        ...item,
                        barcodeScales: columnEValues[index] || "",
                    };
                });
                return { ...prevData, productList: updatedProductList };
            });
        };
        reader.readAsBinaryString(file);
    };

	function handleProductListChange(e, index) {
		var dataCopy = { ...data }
		if (e.target.value.length > 5) {
			dataCopy.productList[index][e.target.name] = e.target.value.substring(0, 5)
		} else {
			dataCopy.productList[index][e.target.name] = e.target.value
		}

		setData(dataCopy)
	}

	function handleSelect(option) {
		getProducts(option.id)
	}

	async function create(e) {
		e.preventDefault();

		var sendData = { ...data }
		sendData.productList = sendData.productList.filter(e => e.barcodeScales !== '')

		const response = await POST('/services/web/api/scales', sendData, { loader: true })
		if (response)
			navigate('/scale')
	}

	async function getPoses() {
		const temporaryPoses = await GET('/services/web/api/pos-helper')
		setPoses(temporaryPoses)

		if (temporaryPoses.length > 0) {
			const temporaryProducts = await GET('/services/web/api/scales-product-helper/' + (reduxSettings.posId ? reduxSettings.posId : temporaryPoses[0]['id']), {}, { loader: true })
			for (let i = 0; i < temporaryProducts.length; i++) {
				temporaryProducts[i]['barcodeScales'] = ''
			}

			setData({ ...data, 'posId': reduxSettings.posId ? reduxSettings.posId : temporaryPoses[0]['id'], 'productList': temporaryProducts })
		}
	}

	async function getProducts(posId) {
		const response = await GET('/services/web/api/scales-product-helper/' + posId)
		for (let i = 0; i < response.length; i++) {
			response[i]['barcodeScales'] = ''
		}
		setData({ ...data, 'productList': response, 'posId': posId })
	}

	function asItIs(type) {
		var dataCopy = { ...data }
		for (let i = 0; i < dataCopy.productList.length; i++) {
			switch (dataCopy['productList'][i]['barcode'].length) {
				case 1:
					dataCopy['productList'][i]['barcodeScales'] = dataCopy['productList'][i]['barcode']
					break;
				case 2:
					dataCopy['productList'][i]['barcodeScales'] = dataCopy['productList'][i]['barcode']
					break;
				case 3:
					dataCopy['productList'][i]['barcodeScales'] = dataCopy['productList'][i]['barcode']
					break;
				case 4:
					if (type === 5) {
						if (dataCopy['productList'][i]['barcode'].length <= 5) {
							dataCopy['productList'][i]['barcodeScales'] = dataCopy['productList'][i]['barcode']
						} else {
							dataCopy['productList'][i]['barcodeScales'] = dataCopy['productList'][i]['barcode'].substring(1, dataCopy['productList'][i]['barcode'].length)
						}
					}
					if (type === 4) {
						dataCopy['productList'][i]['barcodeScales'] = dataCopy['productList'][i]['barcode']
					}
					if (type === 3) {
						dataCopy['productList'][i]['barcodeScales'] = dataCopy['productList'][i]['barcode'].substring(1, dataCopy['productList'][i]['barcode'].length)
					}
					break;
				case 5:
					if (type === 5) {
						dataCopy['productList'][i]['barcodeScales'] = dataCopy['productList'][i]['barcode'].substring(dataCopy['productList'][i]['barcode'].length - 5, dataCopy['productList'][i]['barcode'].length)
					}
					if (type === 4) {
						dataCopy['productList'][i]['barcodeScales'] = dataCopy['productList'][i]['barcode'].substring(1, dataCopy['productList'][i]['barcode'].length)
					}
					if (type === 3) {
						dataCopy['productList'][i]['barcodeScales'] = dataCopy['productList'][i]['barcode'].substring(2, dataCopy['productList'][i]['barcode'].length)
					}
					break;
				default:
					if (type === 5) {
						dataCopy['productList'][i]['barcodeScales'] = dataCopy['productList'][i]['barcode'].substring(dataCopy['productList'][i]['barcode'].length - 5, dataCopy['productList'][i]['barcode'].length)
					}
					if (type === 4) {
						dataCopy['productList'][i]['barcodeScales'] = dataCopy['productList'][i]['barcode'].substring(dataCopy['productList'][i]['barcode'].length - 4, dataCopy['productList'][i]['barcode'].length)
					}
					if (type === 3) {
						dataCopy['productList'][i]['barcodeScales'] = dataCopy['productList'][i]['barcode'].substring(dataCopy['productList'][i]['barcode'].length - 3, dataCopy['productList'][i]['barcode'].length)
					}
					break;
			}
		}

		setData({ ...data, 'productList': dataCopy['productList'] })
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<div className="d-flex">
					<h4 className="vertical-center">
						{t('SCALE')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('CREATE')}</h6>
					</div>
				</div>
			</div>

			<form onSubmit={create} autoComplete="off">
				<div className="card">
					<div className="card-body">
						<div className="row">
							<div className="col-md-3">
								<div style={{width: '150px'}}>
									<div className="btn btn-primary mb-3" style={{width: '100%'}} onClick={exportTableToExcel}>Excel</div> <br />
									<label style={{width: '100%'}} className="custom-file-upload btn btn-outline-primary w-100 text-center">
										<span>{t('IMPORT_FROM_FILE')}</span>
										<input type="file" className="d-none" accept=".xlsx, .xls" onChange={importExcel} />
									</label>
									<hr />
								</div>
								<div className="btn btn-outline-primary mb-3" onClick={() => asItIs(5)}>Последние 5</div> <br />
								<div className="btn btn-outline-primary mb-3" onClick={() => asItIs(4)}>Последние 4</div> <br />
								<div className="btn btn-outline-primary mb-3" onClick={() => asItIs(3)}>Последние 3</div>
								
							</div>
							<div className="col-md-6">
								<Select
									options={poses}
									value={poses.find(option => option.id === data.posId)}
									onChange={handleSelect}
									placeholder=""
									noOptionsMessage={() => t('LIST_IS_EMPTY')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
								<div className="table-responsive" style={{ 'height': 'calc(100vh - 285px)' }}>
									<table id="myTable" className="table table-striped mb-0">
										<thead>
											<tr>
												<th>{t('NAME2')}</th>
												<th>{t('UNIT')}</th>
												<th className="text-center">{t('SALE_PRICE')}</th>
												<th className="text-center">{t('BARCODE')}</th>
												<th className="text-end">{t('BARCODE_FOR_SCALE')}</th>
											</tr>
										</thead>
										<tbody>
											{data.productList.map((item, index) => (
												<tr key={index}>
													<td>{item.rowNum}. {item.productName}</td>
													<td className="text-center">{item.uomName}</td>
													<td className="text-center">{formatMoney(item.salePrice)}</td>
													<td className="text-center">{item.barcode}</td>
													<td className="text-end">
														<input type="number" className="auto-width-input" name="barcodeScales" value={item.barcodeScales}
															onChange={(e) => handleProductListChange(e, index)} />
													</td>
												</tr>
											))
											}
										</tbody>
									</table>
								</div>

								<div className="d-flex mt-5">
									<Link className="btn btn-warning btn-rounded btn-wide me-2 w-100" to="/scale">
										{t('CANCEL')}
									</Link>
									<button type="submit" className="btn btn-primary btn-rounded btn-wide btn-wide w-100">{t('SAVE')}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</>
	)
}

export default CreateUpdate

